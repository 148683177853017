import React from "react";
import { Input, InputProps } from "@rebass/forms";
import { Text } from "rebass";
import styled from "styled-components";

interface Props extends InputProps {
    label: string;
    value?: string;
    onChange?: any;
    placeholder?: string;
    prefix?: any;
}

export default function ({
    label,
    value,
    onChange = () => {},
    prefix,
    ...rest
}: Props) {
    return (
        <div className="form-element">
            <Text fontWeight={500} fontSize={13}>
                {label}
            </Text>
            <Relative>
                {prefix && <Absolute>{prefix}</Absolute>}
                <Input
                    {...rest}
                    value={value}
                    onChange={onChange}
                    pl={prefix ? 40 : 15}
                    mt={2}
                ></Input>
            </Relative>
        </div>
    );
}
const Relative = styled.div({
    position: "relative",
});
const Absolute = styled.div({
    position: "absolute",
    left: 0,
    bottom: 0,
    width: 40,
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});
