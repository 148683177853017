import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Box, Flex } from "rebass";
import HeadingSection from "../../components/Heading/HeadingSection";
import CardUpComming from "../../components/Card/CardUpComming";
import { Clock } from "../../components/Icon";
import CardRowSection from "../../components/Card/CardRowSection";
import ModalPlanInterview from "../interviewSection/ModalPlanInterview";
import { useFetch } from "@fozg/one-point-sdk";
import { OnePointService } from "../../services/OnePointService";
import { OP } from "../../consts/vars";

export default function () {
    const { data } = useFetch(() =>
        OnePointService.getListByName(OP.lists.sections).getItems({
            limit: 2,
            where: [
                {
                    end_time: { $eq: null },
                },
            ],
        })
    );

    return (
        <div>
            <HeadingSection small title="Buổi phỏng vấn tiếp theo" viewMoreUrl="/checklist" />
            <CardRowSection>
                {data.map((item: InterviewSection) => (
                    <CardUpComming {...item} key={item._id} />
                ))}
            </CardRowSection>
            <ButtonsPlanning />
        </div>
    );
}

const ButtonsPlanning = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    return (
        <Flex flexDirection="column" alignItems="center" mt={5}>
            {isModalOpen && (
                <ModalPlanInterview
                    visible
                    onClose={() => {
                        setModalOpen(false);
                    }}
                />
            )}
            <ButtonCreateInterview
                onClick={() => {
                    setModalOpen(true);
                }}
            >
                <Flex alignItems="center">
                    <Clock width={30} />
                    <Box marginLeft="5px">Plan an interview section</Box>
                </Flex>
            </ButtonCreateInterview>
            {/* <Text color="#555" fontSize={1}>
                or
            </Text>
            <ButtonPlan to="/create">
                <Lightning width={20} />
                <Box ml="5px">Start an interview section now</Box>
            </ButtonPlan> */}
        </Flex>
    );
};

const ButtonCreateInterview = styled.div({
    cursor: "pointer",
    background: "#DDEBF6",
    padding: "15px 20px",
    border: "2px solid #1D85E1",
    borderRadius: 10,
    fontWeight: 600,
    color: "#1D85E1",
    fontSize: 18,
    display: "inline-block",
    textDecoration: "none",
    boxShadow: "0 5px 12px rgba(0,0,0,.1)",
    transition: "all .2s",
    ":hover": {
        background: "#1D85E1",
        color: "#fff",
    },
});
// const ButtonPlan = styled(Link)({
//     display: "flex",
//     color: "#222",
//     fontWeight: 500,
//     textDecoration: "none",
//     ":hover": {
//         textDecoration: "underline",
//     },
// });
