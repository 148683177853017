import React from "react";
import { Flex, Box } from "rebass";
import styled, { keyframes, css } from "styled-components";
import LoadingLogo from "../LoadingLogo";
import Badge from "../Badge";

export default function () {
    return (
        <Logo alignItems="center">
            <LoadingLogo width={40} height={40} />
            <Box margin="10px" />
            <LogoText>InterviewerPlus</LogoText>
            <Badge>Beta</Badge>
        </Logo>
    );
}

const LogoText = styled.h3({
    margin: 0,
    padding: 0,
});
const Logo = styled(Flex)({
    // height: 80,
    // background: "#f1f1f1",
    padding: "10px 15px 10px",
    // border: "1px solid #eee",
    // boxShadow: "0 5px 10px rgba(0,0,0,.08)",
    // position: "fixed",
    borderRadius: 10,
    // left: 20,
    // zIndex: 20,
});
