export const OP = {
    appName: "interviewer_plus",
    lists: {
        topics: "topics",
        questions: "questions",
        checklists: "checklists",
        sections: "sections",
        results: "results"
    },
};
