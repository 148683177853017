import React from "react";
import { Heading, Box, Flex } from "rebass";
import { Button, ButtonVariants } from "@fozg/fozg-ui-elements";
import { FONT } from "../../utils/theme";
import { useHistory } from "react-router-dom";

export type HeadingPageActionsType = [
    {
        title: string;
        onClick?: (e: any) => void;
        variant?: ButtonVariants;
        icon?: any;
    }
];

type Props = {
    title: string;
    backUrl?: string;
    onBackClick?: Function;
    actions?: HeadingPageActionsType;
    color?: string
};

export default function ({ title, backUrl, onBackClick, actions, color }: Props) {
    const history = useHistory();
    return (
        <Flex justifyContent="space-between" alignItems="center">
            <Box margin="2em 0">
                <Button
                    ml={-2}
                    variant={ButtonVariants.outline}
                    iconBefore={
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            width={20}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M7 16l-4-4m0 0l4-4m-4 4h18"
                            />
                        </svg>
                    }
                    onClick={() => {
                        if (backUrl) {
                            history.push(backUrl);
                        } else {
                            history.goBack();
                        }
                    }}
                    color={color}
                >
                    Back
                </Button>

                <Heading fontSize={5} fontFamily={FONT.SegoeUI} color={color}>
                    {title}
                </Heading>
            </Box>
            <Flex>
                {actions
                    ? actions.map((action) => (
                          <Button
                              variant={action.variant}
                              onClick={action.onClick}
                              key={action.title}
                              iconBefore={action.icon}
                          >
                              {action.title}
                          </Button>
                      ))
                    : null}
            </Flex>
        </Flex>
    );
}
