import styled from "styled-components";

export default styled.div({
    borderRadius: 20,
    display: "inline-block",
    padding: "0px 10px",
    fontWeight: 500,
    color: "#0366d6",
    backgroundColor: "#f1f8ff",
    fontSize: 13,
    lineHeight: 1.5,
});
