import OnePointSDK from "@fozg/one-point-sdk";
import { OP } from "../consts/vars";

const ONEPOINT_ENDPOINT = "https://op.fozg.dev"

export let OnePointService = new OnePointSDK(
    ONEPOINT_ENDPOINT,
    // window.localStorage.getItem("token") || ""
).getAppByName(OP.appName);

export function reInitService() {
    OnePointService = new OnePointSDK(
        ONEPOINT_ENDPOINT,
        window.localStorage.getItem("token") || ""
    ).getAppByName(OP.appName);
}

export const OnePointServiceBase = new OnePointSDK(
    ONEPOINT_ENDPOINT,
    window.localStorage.getItem("token") || ""
)