import React, { ReactChild, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import TextareaInput from "../../components/Forms/TextareaInput";
import { CurentFocusType, getState, setPoint, getValueByPosition } from "./MatrixMark";

const ModalInput = ({ currentFocus, onClose }: { currentFocus: CurentFocusType; onClose: any }) => {
    const [value, setValue] = useState(getValueByPosition(currentFocus.t, currentFocus.x, currentFocus.y).toString());

    const onKeyPress = useCallback(
        (e: KeyboardEvent) => {
            switch (e.key) {
                case "Enter":
                    if (e.ctrlKey || e.metaKey) {
                        e.preventDefault();
                        setPoint(currentFocus, value);
                        onClose();
                    }
                    break;
                case "Escape": {
                    e.preventDefault();
                    setPoint(currentFocus, value);
                    onClose();
                    break;
                }
            }
        },
        [value]
    );

    useEffect(() => {
        setValue(value + (window as any).appendString);
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", onKeyPress);
        return () => {
            document.removeEventListener("keydown", onKeyPress);
        };
    }, [value]);

    return (
        <CustomModalZone>
            <CustomModal>
                <TextareaInput
                    label="Nhập nội dung sau đó ấn (Ctrl + Enter hoặc ESC) để hoàn tất"
                    autoFocus
                    value={value}
                    height={280}
                    onChange={(e: any) => setValue(e.target.value)}
                />
            </CustomModal>
        </CustomModalZone>
    );
};

export default ModalInput;

const CustomModalZone = styled.div`
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 400px;
    z-index: 100;
`;
const CustomModal = styled.div`
    box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.1);
    width: 50%;
    max-width: 800px;
    background: #fff;
    margin: auto;
    padding: 20px;
    height: 400px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`;
