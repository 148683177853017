import { useFetch } from "@fozg/one-point-sdk";
import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Box, Text } from "rebass";
import styled from "styled-components";
import StaticCard from "../../components/Card/StaticCard";
import Container from "../../components/Container";
import LoadingList from "../../components/LoadingList";
import Guide from "../../components/Guide";
import HoverCard from "../../components/HoverCard";
import { OP } from "../../consts/vars";
import { OnePointService } from "../../services/OnePointService";
import LevelDisplay from "../../components/LevelDisplay";

export default function () {
    const { data, loading }: { data: InterviewSection[]; loading: boolean } = useFetch(() =>
        OnePointService.getListByName(OP.lists.sections).getItems({
            where: [
                {
                    end_time: { $ne: null },
                },
            ],
        })
    );
    return (
        <Container>
            <Guide
                title="Kết quả phỏng vấn"
                subTitle="Tổng hợp kết quả các buổi phỏng vấn của bạn ở đây"
                lines={["Với các kết quả, bạn có thể sửa hoặc share kết quả cho người khác"]}

            />
            <StaticCard mt={4}>
                {loading && <LoadingList />}
                {data.map((item, idx) => (
                    <Link to={`/result/${item._id}`} key={item._id}>
                        <HoverCard key={idx} p={2}>
                            <Line p={2}>
                                <Text fontWeight={600} fontSize={18}>
                                    {item.name} - {item.job_title}
                                </Text>
                                <Text>Checklist: {item.checklists.title}</Text>
                                <Text fontSize={"13px"} color="#aaa">
                                    Thời gian phỏng vấn: {moment(item.started_time).format("lll")} / Kết thúc: {moment(item.end_time).format("lll")}
                                </Text>
                            </Line>
                        </HoverCard>
                    </Link>
                ))}
            </StaticCard>
        </Container>
    );
}

const Line = styled(Box)`
    // border-bottom: 1px solid #eee;
`;
