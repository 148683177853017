import React from "react";
import PageLayout from "../../components/layouts/PageLayout";
import Container from "../../components/Container";
import { Plus } from "../../components/Icon";
import { useHistory } from "react-router-dom";
import QuestionSection from "../../modules/QuestionSection";
import StaticCard from "../../components/Card/StaticCard";
import Guide from "../../components/Guide";

export default function () {
    const history = useHistory();
    return (
        <Container>
            <Guide
                width={["100%", '50%']}
                title="Câu hỏi"
                subTitle="Tạo câu hỏi hoặc câu hỏi con cùng với gọi ý trả lời cho từng câu hỏi"
                lines={[
                  "Khi tạo câu hỏi, bạn sẽ chọn 1 trong các topic đã được tạo sẵn, các câu hỏi có cùng topic sẽ tự động được nhóm lại ở checklist."
                ]}
                loading={false}
            />
            <PageLayout
                pageTitle="Câu hỏi"
                actions={[
                    {
                        title: "Tạo câu hỏi",
                        icon: <Plus />,
                        onClick: () => {
                            history.push("/question/create");
                        },
                    },
                ]}
            >
                <StaticCard>
                    <QuestionSection headerShowed={false} />
                </StaticCard>
            </PageLayout>
        </Container>
    );
}
