import { Button, ButtonVariants } from "@fozg/fozg-ui-elements";
import React, { ReactElement } from "react";
import { Box, Flex, Heading, Text } from "rebass";
import StaticCard from "../Card/StaticCard";
import { Facebook, Code } from "react-content-loader";
import LoadingList from "../LoadingList";

type Props = {
    width?: string | (string | number)[];
    title?: string | ReactElement;
    subTitle?: string;
    lines?: (string | undefined | any)[];
    actions?: {
        title: string;
        onClick?: () => void;
        variant?: ButtonVariants;
        iconBefore?: any;
    }[];
    rightPanel?: React.ReactChild;
    loading?: boolean;
};

export default function ({ width = ["100%", "100%", "80%", "50%"], title, subTitle, lines, actions = [], rightPanel, loading }: Props) {
    if (!title || loading) {
        return (
            <StaticCard width={width}>
                <LoadingList />
            </StaticCard>
        );
    }
    return (
        <StaticCard width={width}>
            <Flex>
                <Flex flex={1} flexDirection="column">
                    <Heading>{title}</Heading>
                    {subTitle && (
                        <Text fontWeight="500" mt={2}>
                            {subTitle}
                        </Text>
                    )}
                    {lines?.map((line, idx) => (
                        <Text mt={2} key={idx} color="#444" lineHeight="1.4em">
                            {line}
                        </Text>
                    ))}
                    {actions?.length !== 0 && (
                        <Box mt={3}>
                            {actions?.map((action, idx) => (
                                <Button onClick={action.onClick} key={idx} variant={action.variant} mr={2} iconBefore={action.iconBefore}>
                                    {action.title}
                                </Button>
                            ))}
                        </Box>
                    )}
                </Flex>
                <Box>{rightPanel}</Box>
            </Flex>
        </StaticCard>
    );
}
