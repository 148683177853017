import React, { useState } from "react";
import { useFetch } from "@fozg/one-point-sdk";
import CardRowSection from "../../components/Card/CardRowSection";
import CardUpComming from "../../components/Card/CardUpComming";
import StaticCard from "../../components/Card/StaticCard";
import Container from "../../components/Container";
import Guide from "../../components/Guide";
import { OP } from "../../consts/vars";
import { OnePointService } from "../../services/OnePointService";
import ModalPlanInterview from "./ModalPlanInterview";
import LoadingList from "../../components/LoadingList";

export default function () {
    const { data, loading } = useFetch(() => OnePointService.getListByName(OP.lists.sections).getItems());
    const [modalPlanVisible, setModalPlanVisible] = useState(false);

    return (
        <Container>
            {modalPlanVisible && (
                <ModalPlanInterview
                    visible
                    onClose={() => {
                        setModalPlanVisible(false);
                    }}
                />
            )}
            <Guide
                title="Buổi phỏng vấn"
                subTitle="Buổi phỏng vấn là nơi để lên lịch phỏng vấn ứng viên, dựa theo Checklist đã chọn phù hợp."
                lines={[
                    "Một buổi phỏng vấn gồm các thông tin cơ bản về ứng viên như Tên, Vị trí tuyển dụng, tuổi, cv..., Checklist chọn cho vị trí mà UV ứng tuyển, và thời gian phỏng vấn.",
                    "Để lên lịch phỏng vấn, bấm 'Tạo buổi phỏng vấn'",
                ]}
                actions={[
                    {
                        title: "Tạo buổi phỏng vấn",
                        onClick: () => {
                            setModalPlanVisible(true);
                        },
                    },
                ]}
            ></Guide>

            <StaticCard mt={4}>
                {loading ? (
                    <LoadingList />
                ) : (
                    <CardRowSection>
                        {data.map((item: InterviewSection) => (
                            <CardUpComming {...item} key={item._id} />
                        ))}
                    </CardRowSection>
                )}
            </StaticCard>
        </Container>
    );
}
