import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Heading, Flex, Text, Box } from "rebass";
import HoverCard from "./HoverCard";
import Rating from "./Rating";
import Badge from "../components/Badge";
import { Link } from "react-router-dom";

type Props = {
    question: string;
    topics?: { name: string };
    rate?: number;
    createdBy?: string;
    created?: string;
    _id: string;
    topicVisibled?: boolean
};
export default function ({
    question,
    topics,
    rate = 1,
    createdBy = "you",
    created,
    _id,
    topicVisibled = true
}: Props) {
    return (
        <Wrapper>
            <Link to={`/question/${_id}`}>
                <Flex justifyContent="space-between" alignItems="center">
                    <Flex alignItems="center">
                        {topicVisibled && topics && (
                            <Box mr={2}>
                                <Badge>{topics?.name}</Badge>
                            </Box>
                        )}
                        <Heading fontSize={"15px"} fontWeight={500}>
                            {question}
                        </Heading>
                    </Flex>
                    <Box>
                        <Rating rate={rate} text="" />
                    </Box>
                </Flex>
                {topicVisibled && <Flex alignItems="center" mt={1}>
                    <Text fontSize={"13px"} color="#aaa">
                        Created by {createdBy} {moment(created).fromNow()}
                    </Text>
                </Flex>}
            </Link>
        </Wrapper>
    );
}

const Wrapper = styled(HoverCard)({
    padding: 15,
    background: "#fff",
    // // border: "1px solid #eee",
    // borderTop: "none",
    // ":first-child": {
    //     // border: "1px solid #eee",
    //     borderTopRightRadius: 10,
    //     borderTopLeftRadius: 10,
    // },
    // ":last-child": {
    //     borderBottomRightRadius: 10,
    //     borderBottomLeftRadius: 10,
    // },
});
