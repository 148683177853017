import React from "react";
import styled from "styled-components";
import { Flex } from "rebass/styled-components";

export default ({ children }: any) => (
    <CardRowSection flexWrap="wrap" margin={-10}>
        {children}
    </CardRowSection>
);

const CardRowSection = styled(Flex)``;
