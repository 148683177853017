import styled from "@emotion/styled";

export default styled.div({
    background: "#fff",
    border: "1px solid #eee",
    boxShadow: "0 3px 5px rgba(0,0,0,.08)",
    padding: 30,
    borderRadius: 15,
    ".form-element": {
        marginBottom: 20,
    },
});
