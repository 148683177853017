import { Box } from "rebass";
import styled from "styled-components";
import { COLORS } from "../utils/theme";

export default styled(Box)`
    :hover {
        box-shadow: inset 0 0 0 1px ${COLORS.MAIN};
        background: #f2f2f2;
        cursor: pointer;
        // border-color: ${COLORS.MAIN};
    }
`;
