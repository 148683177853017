import React from "react";

export default function ({ className }: { className: string }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="374"
            height="381.459"
            viewBox="0 0 374 381.459"
            className={className}
        >
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="0.5"
                    x2="0.5"
                    y2="1"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stop-color="#e6e6e6" />
                    <stop offset="1" stop-color="#afafaf" />
                </linearGradient>
            </defs>
            <g
                id="Group_80"
                data-name="Group 80"
                transform="translate(-3985 1010)"
            >
                <path
                    id="Path_138"
                    data-name="Path 138"
                    d="M15,0H70.227a15,15,0,0,1,15,15V284a15,15,0,0,1-15,15H15A15,15,0,0,1,0,284V15A15,15,0,0,1,15,0Z"
                    transform="matrix(0.999, 0.035, -0.035, 0.999, 4163.743, -930.333)"
                    opacity="0.74"
                    fill="url(#linear-gradient)"
                />
                <path
                    id="Path_139"
                    data-name="Path 139"
                    d="M15,0H67.737a15,15,0,0,1,15,15V293a15,15,0,0,1-15,15H15A15,15,0,0,1,0,293V15A15,15,0,0,1,15,0Z"
                    transform="matrix(-0.035, 0.999, -0.999, -0.035, 4356.463, -815.406)"
                    opacity="0.74"
                    fill="url(#linear-gradient)"
                />
                <path
                    id="Path_7"
                    data-name="Path 7"
                    d="M15,0,79.6-4.659C87.883-4.659,101,6.716,101,15V350a15,15,0,0,1-15,15H15c-8.284,0-22.19-9.587-22.19-17.871L0,15A15,15,0,0,1,15,0Z"
                    transform="translate(4126 -1004)"
                    fill="#e8505b"
                />
                <path
                    id="Path_8"
                    data-name="Path 8"
                    d="M15,0H86a15,15,0,0,1,15,15V350c0,8.284-9.958,22.3-18.242,22.3L15,365A15,15,0,0,1,0,350L-5.061,21.288C-5.061,13,6.716,0,15,0Z"
                    transform="translate(4359 -872) rotate(90)"
                    fill="#e8505b"
                />
                <path
                    id="Path_140"
                    data-name="Path 140"
                    d="M15,0H86a15,15,0,0,1,15,15V350a15,15,0,0,1-15,15H15A15,15,0,0,1,0,350V15A15,15,0,0,1,15,0Z"
                    transform="translate(4117 -1010)"
                    fill="#f9d56e"
                />
                <path
                    id="Path_141"
                    data-name="Path 141"
                    d="M15,0H86a15,15,0,0,1,15,15V350a15,15,0,0,1-15,15H15A15,15,0,0,1,0,350V15A15,15,0,0,1,15,0Z"
                    transform="translate(4350 -878) rotate(90)"
                    fill="#f9d56e"
                />
                <path
                    id="Path_4"
                    data-name="Path 4"
                    d="M87.228-134.1q-10.518,0-17.975-4.089t-7.457-9.8q0-5.792,7.457-9.8t17.975-4q10.675,0,18.21,4.047t7.535,9.754q0,5.708-7.614,9.8T87.228-134.1ZM27.1-59.109l20.251-47.136H26.79l7.378-17.58h66.99L73.679-59.109H94.243l-7.378,17.58H3.085l7.378-17.58Z"
                    transform="translate(4045 -701.74)"
                    fill="#e6e6e6"
                    style={{ mixBlendMode: "color-burn", isolation: "isolate" }}
                />
                <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M72.986-119.293a20.766,20.766,0,0,1-8.637-1.825c-2.034-.892-7.916-4.216-9.88-6.206-4.091-4.147-2.411-7.3-2.411-13.091a20.192,20.192,0,0,1,6.136-14.9c4.091-4.061,4.672-9.384,10.442-9.384,5.857,0,15.2,5.345,19.336,9.449a20.148,20.148,0,0,1,6.2,14.837,20.256,20.256,0,0,1-6.266,14.9A20.449,20.449,0,0,1,72.986-119.293ZM23.507-5.22l16.665-71.7H23.248l-5.611-3.372,11.683-23.37,49.53-3.367,5.6,3.367L61.835-5.22,73.01-8.565,78.759-5.22,72.687,21.522H3.741l-5.675-3.4L9.813-5.22Z"
                    transform="translate(4035 -774.989)"
                    fill="#f3ecc2"
                />
                <path
                    id="Path_4-2"
                    data-name="Path 4"
                    d="M72.33-119.674a20.025,20.025,0,0,1-14.792-6.22A20.468,20.468,0,0,1,51.4-140.8a20.192,20.192,0,0,1,6.136-14.9,20.236,20.236,0,0,1,14.792-6.091,20.493,20.493,0,0,1,14.986,6.156,20.148,20.148,0,0,1,6.2,14.837,20.256,20.256,0,0,1-6.266,14.9A20.449,20.449,0,0,1,72.33-119.674ZM22.851-5.6,39.516-77.3H22.592l6.072-26.742H83.793L61.179-5.6H78.1L72.031,21.142H3.085L9.157-5.6Z"
                    transform="translate(4030.002 -778)"
                    fill="#14b1ab"
                />
            </g>
        </svg>
    );
}
