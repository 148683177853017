import * as React from "react";
import { Box } from "rebass";
import { useFetch } from "@fozg/one-point-sdk";
import HeadingSection from "../../components/Heading/HeadingSection";
import QuestionLine from "../../components/QuestionLine";
import { OnePointService } from "../../services/OnePointService";
import { OP } from "../../consts/vars";
import LoadingList from "../../components/LoadingList";

type Props = {
    headerShowed?: boolean;
    isHomePage?: boolean;
};
export default function ({ headerShowed = true, isHomePage }: Props) {
    const { data, loading } = useFetch(() => OnePointService.getListByName(OP.lists.questions).getItems({ limit: isHomePage ? 5 : 200 }));

    if (loading) {
        return <LoadingList />;
    }
    return (
        <>
            {headerShowed && <HeadingSection title="Your question" viewMoreUrl="/question" />}
            {data.map((item: any) => (
                <QuestionLine {...item} />
            ))}
        </>
    );
}
