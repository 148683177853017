export const defaultQuestions = {
    topic: "Tổng kết, các câu hỏi của ứng viên",
    questions: [
        {
            question: "Thời gian sớm nhất có thể đi làm?",
            answer: `Nên hỏi ứng viên khi kết thúc buổi phỏng vấn dù kết quả có tốt hay không.

***Không cần cho điểm ở câu này***
            `,
            rate: 0,
            created: "",
            createdBy: "system",
            _id: "default_q_1",
        },
        {
            question: "Expect vị trí, carier path?",
            answer: `
***Không cần cho điểm ở câu này***
`,
            rate: 0,
            created: "",
            createdBy: "system",
            _id: "default_q_2",
        },
        {
            question: "Câu hỏi khác của ứng viên",
            answer: `
***Không cần cho điểm ở câu này***
            
            `,
            rate: 0,
            created: "",
            createdBy: "system",
            _id: "default_q_3",
        },
        {
            question: "Nơi làm việc mong muốn",
            answer: `
Nên lưu ý cho UV rằng Fsoft có nhiều nơi làm việc khác nhau nếu bạn chưa biết, chưa từng làm ở FS

***Không cần cho điểm ở câu này***
`,
            rate: 0,
            created: "",
            createdBy: "system",
            _id: "default_q_4",
        },
    ],
}

export const defaultRating = {
    commentByTopic: "Nhận xét chung",
    byQuestions: [
        {
            point: undefined,
            commentByQuestion: "",
        },
        {
            point: undefined,
            commentByQuestion: "",
        },
        {
            point: undefined,
            commentByQuestion: "",
        },
        {
            point: undefined,
            commentByQuestion: "",
        },
    ],
}