import React from "react";
import { Box } from "rebass";

export default ({ children }: any) => (
    <Box
        width="100%"
        maxWidth={["100%", 600, 900, 1200]}
        margin="auto"
        padding="0 20px"
    >
        {children}
    </Box>
);
