import React, { useEffect, useState } from "react";
import moment from "moment";
import toast from "react-hot-toast";
// @ts-ignore
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { Box, Text } from "rebass";
import StaticCard from "../../components/Card/StaticCard";
import Guide from "../../components/Guide";
import Logo from "../../components/Logo";
import { OnePointServiceBase } from "../../services/OnePointService";
import MatrixMark, { setState, clearData } from "../processing/MatrixMark";
import { calcTotalPoint } from "../results/ResultDetail";
import LevelDisplay from "../../components/LevelDisplay";

export default function () {
    const { key } = useParams<{ key: string }>();
    const [data, setData] = useState<InterviewResult | undefined>(undefined);
    const [totalPoint, setTotalPoint] = useState(0);

    useEffect(() => {
        fetchData();
        return () => {
            clearData();
        };
    }, []);

    const fetchData = async () => {
        const resultsRes = await OnePointServiceBase.getPublicItem(key);

        if (resultsRes.isError) {
            toast.error("Ú ù!");
            return;
        }

        const firstResult = Array.isArray(resultsRes.data) ? resultsRes.data[0] : resultsRes.data;
        if (firstResult) {
            setData(firstResult);
            setState({
                points: firstResult.points,
                topics: firstResult.topics,
                checklist: firstResult.checklist,
            });
            setTotalPoint(calcTotalPoint(firstResult.points, firstResult.topics));
        }
    };

    return (
        <>
            <Helmet>
                <title>{`Kết quả phỏng vấn: ${data?.session?.name}`}</title>
            </Helmet>
            {/* <StaticCard mt={4} width={["100%", "1400px"]} marginX={[4, "auto"]}> */}
            <Box mt={4} marginX={[1, 2, 3, 4]}>
                <Guide
                    // loading={!data?.session?.name}
                    width={["80%", "800px"]}
                    title={"Kết quả phỏng vấn"}
                    subTitle={!data?.session?.name ? "Loading.." : data?.session?.name + " - " + data?.session?.job_title}
                    lines={[
                        <Text fontSize={14}>Checklist: {data?.checklist?.title}</Text>,
                        <Text fontSize={14}>Interviewer: {data?.createdBy}</Text>,
                        <Text fontSize={14}>Ngày phỏng vấn: {moment(data?.created).format("lll")}</Text>,
                    ]}
                    rightPanel={
                        <Box>
                            <Logo />
                            <LevelDisplay point={totalPoint} />
                        </Box>
                    }
                />
            </Box>
            {/* </StaticCard> */}
            <StaticCard mt={4} marginX={[1, 2, 3, 4]}>
                <MatrixMark disable />
            </StaticCard>
        </>
    );
}

function diffMinute(b: any, a: any) {
    if (!a || !b) return 0;
    return moment(a).diff(b, "minutes");
}
