import React from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ButtonVariants, confirm, confirmTypes } from "@fozg/fozg-ui-elements";
import { Text, Box, Flex } from "rebass";
import BaseCard, { BaseCardActions } from "./BaseCard";
import CardChecklist from "./CardChecklist";
import { GhimIcon } from "../Icon";
import { OnePointService } from "../../services/OnePointService";
import { OP } from "../../consts/vars";
import { FiEdit2, FiTrash2 } from "react-icons/fi";

type Props = {
    _id: string;
    time?: string | number;
    timeEnd?: string | number;
    name: string;
    job_title: string;
    createdBy: string;
    checklists: Checklist;
} & InterviewSection;

export default function ({ _id, time, timeEnd, name, createdBy, job_title, checklists, started_time, end_time }: Props) {
    const timeColor = getTimeColor(time, timeEnd);
    const history = useHistory();
    const actions = end_time
        ? [{ title: "✔ Done", variant: ButtonVariants.outline, onClick: () => {} }]
        : [
              {
                  title: started_time ? "Tiếp tục phỏng vấn" : "Bắt đầu phỏng vấn",
                  onClick: () => {
                      history.push("/processing/" + _id);
                  },
                  variant: started_time ? ButtonVariants.warning : ButtonVariants.default,
              },
          ];

    return (
        <BaseCard
            borderHoverColor={"transparent"}
            borderHoverWidth={2}
            width={[1, 1, 1 / 2]}
            backgroundColor="#fff"
            marginRight={[0, 20, 30]}
            actions={actions}
            moreActions={[
                {
                    icon: <FiEdit2 />,
                    title: "Sửa",
                    onClick: () => {
                        confirm({
                            title: "Sửa thông tin",
                            message: "Chức năng chưa có!",
                            onOk: () => {},
                            type: confirmTypes.default,
                        });
                    },
                },
                {
                    icon: <FiTrash2 />,
                    title: "Hủy",
                    onClick: () => {
                        confirm({
                            title: "Cancel Interview Section?",
                            message: "Please take a note that this action will unable to redo.",
                            type: confirmTypes.warning,
                            onOk: () => {
                                OnePointService.getListByName(OP.lists.sections).deleteItem(_id);
                            },
                            primaryButton: "Cancel this Section",
                        });
                    },
                },
            ]}
        >
            <Inner>
                <Flex justifyContent="space-between">
                    <Box>
                        <Text fontSize={1} fontWeight={400}>
                            Created by: {createdBy}
                        </Text>
                        {time && (
                            <Text fontWeight={500} fontSize={1} color={timeColor}>
                                Interview time: {moment(time).format("HH:mm MMM DD")}
                            </Text>
                        )}
                    </Box>
                </Flex>
                <Box mt={3}>
                    <Text fontSize={3} fontWeight={500}>
                        {name}
                    </Text>
                    <Text fontSize={2}>{job_title}</Text>
                </Box>
                <WrapCard mt={20} justifyContent="center">
                    <Ghim>
                        <GhimIcon width={40} color="#aaa" />
                    </Ghim>
                    <CardChecklist width={"100%"} dropShadow actionsShowed={false} {...checklists} {...checklists.summary} />
                </WrapCard>
            </Inner>
        </BaseCard>
    );
}

const getTimeColor = (timeStart?: any, timeEnd?: any): string => {
    const isOver = moment().valueOf() - moment(timeStart).valueOf() > 0;
    if (isOver) {
        return "red";
    } else {
        return "green";
    }
};

const Inner = styled.div({
    overflow: "hidden",
    height: 300,
    marginBottom: -20,
});
const Ghim = styled.div({
    position: "absolute",
    top: 0,
    right: 0,
    opacity: 0.5,
});
const WrapCard = styled(Flex)({
    position: "relative",
    width: "calc(100% - 80px)",
    // transform: 'translateY(0%)',
    transition: "transform .2s",
    ":hover": {
        transform: "translateY(-20%)",
        [Ghim]: {
            display: "none",
        },
    },
});
