import React, { useState } from "react";
import { Box, Text } from "rebass";
import { ButtonVariants, Modal } from "@fozg/fozg-ui-elements";
import Datetime from "react-datetime";
import moment from "moment";

import TextInput from "../../components/Forms/TextInput";
import { OP } from "../../consts/vars";
import SelectOP from "../../components/Select/SelectOP";
import { OnePointService } from "../../services/OnePointService";
import toast from "react-hot-toast";

type Props = {
    onSelected?: void;
    visible?: boolean;
    onClose?: () => void;
};
export default function ({ visible, onClose }: Props) {
    const [name, setName] = useState();
    const [job_title, setJobTitle] = useState();
    const [time, setTime] = useState(moment());
    const [checklist, setChecklist] = useState<{ value: string; label: string }>();

    const onCreateClick = () => {
        OnePointService.getListByName(OP.lists.sections)
            .addItem({
                name,
                job_title,
                time: time.toISOString(),
                checklists_id: checklist?.value,
            })
            .then((res) => {
                if (res.isError) {
                    toast.error(res.message);
                } else {
                    toast.success("Create checklist successfully!");
                    onClose && onClose();
                }
            });
    };

    return (
        <Modal
            visible={visible}
            title={"Plan an Interview Section"}
            width={600}
            actions={[
                { title: "Create", onClick: onCreateClick },
                {
                    title: "Cancel",
                    onClick: onClose,
                    variant: ButtonVariants.outline,
                },
                // { title: "Add", variant: "primary", icon: <Check /> },
            ]}
        >
            <Box p={3}>
                <TextInput
                    label="Candidate name"
                    mb={2}
                    value={name}
                    onChange={(e: any) => {
                        setName(e.target.value);
                    }}
                ></TextInput>
                <TextInput
                    label="Job title"
                    mb={2}
                    value={job_title}
                    onChange={(e: any) => {
                        setJobTitle(e.target.value);
                    }}
                ></TextInput>
                <SelectOP
                    label="Choose a Checklist"
                    filter={{
                        listName: OP.lists.checklists,
                        lookupField: "title",
                    }}
                    onChange={(v: any) => setChecklist(v)}
                />
                <Box mt={2}>
                    <Text fontSize={12} fontWeight={500} mb={2}>
                        Choose a time
                    </Text>
                    <Datetime value={time} onChange={(t) => setTime(moment(t))} />
                </Box>
            </Box>
        </Modal>
    );
}
