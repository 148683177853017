import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box, Flex, Text } from "rebass";
import { chain } from "lodash";
import { Label } from "@rebass/forms";
import styled from "styled-components";
import toast from "react-hot-toast";
import { Button, ButtonVariants } from "@fozg/fozg-ui-elements";

import PageLayout from "../../components/layouts/PageLayout";
import Container from "../../components/Container";
import { Check, Plus } from "../../components/Icon";
import Badge from "../../components/Badge";
import MasterCard from "../../components/layouts/MasterCard";
import TextInput from "../../components/Forms/TextInput";
import TextareaInput from "../../components/Forms/TextareaInput";
import ModalAddQuestions from "../../modules/checklists/ModalAddQuestions";
import { addQuestion, updateWeight, useStore } from "./state.light";
import { OnePointService } from "../../services/OnePointService";
import Guide from "../../components/Guide";
import { OP } from "../../consts/vars";
import { setState } from "./state.light";
import { FiSave } from "react-icons/fi";

export default function () {
    const param = useParams<{ id: string }>();
    const { questions } = useStore<{ questions: Question[] }>((state) => state);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState();

    const history = useHistory();
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        if (param.id) {
            OnePointService.getListByName(OP.lists.checklists)
                .getItemById(param.id)
                .then((res) => {
                    if (res.isError) {
                        toast.error(res.message);
                    } else {
                        const { title, questions, description } = res.data;
                        setTitle(title);
                        setDescription(description);
                        setState({ questions });
                    }
                });
        }
    }, []);

    const groupedByTopic = chain(questions)
        .groupBy("topics.name")
        .map((value, key) => ({
            topic: key,
            questions: value,
        }))
        .value();

    const onSaveChecklist = () => {
        const matrix = [0, 0, 0, 0, 0];
        chain(questions.map((q) => ({ ...q, rate: q.rate ? q.rate : 1 })))
            .groupBy("rate")
            .map((value, key) => ({
                rate: key,
                count: value.length,
                questions: value,
            }))
            .value()
            .forEach((i) => {
                matrix[5 - parseInt(i.rate)] = i.count;
            });

        let summary: ChecklistSummary = {
            numberOfQuiz: questions.length,
            numberOfTopic: groupedByTopic.length,
            matrix: matrix,
            topics: groupedByTopic.map((item) => item.topic),
        };

        if (param.id) {
            // update
            OnePointService.getListByName("checklists").updateItem(param.id, {
                title,
                description,
                questions,
                summary,
            }).then(res => {
                if(res.isError) return toast.error(res.message)
                toast.success("Update thành công!");
                history.push("/checklist");
            })
        } else {
            // Create new
            OnePointService.getListByName("checklists")
                .addItem({
                    title,
                    description,
                    questions,
                    summary,
                })
                .then((res) => {
                    if (res.isError) {
                        toast.error(res.message);
                    } else {
                        toast.success("Tạo checklist thành công!");
                        history.push("/checklist");
                    }
                });
        }
    };

    return (
        <>
            <Container>
                <Guide
                    title="Tạo checklist"
                    subTitle="Một checklist bao gồm Tiêu đề (ví dụ: Senior Fullstack NodeJS..), giới thiệu ngẵn gọn và các câu hỏi."
                    lines={[
                        "Các câu hỏi cùng topic sẽ được tự động nhóm lại theo topic",
                        "Các topic lớn nên có là: Kinh nghiệm, Tech, Phân tích, Thiết kế, Quản lý, Kỹ năng mềm, Ngoại ngữ, Chứng chỉ...",
                    ]}
                ></Guide>
                <PageLayout
                    // color="#fff"
                    pageTitle={param.id ? title : "Tạo checklist"}
                    backUrl="/checklist"
                    actions={[
                        {
                            title: param.id ? "Cập nhật" : "Lưu",
                            icon: <FiSave />,
                            // variant: ",
                            onClick: () => {
                                onSaveChecklist();
                            },
                        },
                    ]}
                >
                    <MasterCard>
                        <TextInput label="Title" value={title} onChange={(e: any) => setTitle(e.target.value)} placeholder="Job title/position" />
                        <TextareaInput
                            label="Description"
                            value={description}
                            onChange={(e: any) => setDescription(e.target.value)}
                            placeholder="Job title/position"
                        />
                        <Label>Questions</Label>

                        <Box mt={2} mb={2}>
                            {groupedByTopic.map((group: any, idx) => (
                                <Box>
                                    <BadgeLine>
                                        <Badge>{group.topic !== "undefined" ? group.topic : "No topic"}</Badge>
                                    </BadgeLine>
                                    {group.questions.map((q: Question) => (
                                        <Flex alignItems="center">
                                            <Flex flex={1}>
                                                <Text fontWeight={600}>{q.question}</Text>
                                                {/* <QuestionLine {...q} key={q._id} topicVisibled={false} /> */}
                                            </Flex>
                                            <Flex alignItems="center">
                                                <TextInput
                                                    label=""
                                                    placeholder="weight"
                                                    width="80px"
                                                    value={q.weight?.toString()}
                                                    onChange={(e: any) => updateWeight(q._id, e.target.value)}
                                                    prefix={
                                                        <Text fontSize={12} fontWeight={600}>
                                                            %
                                                        </Text>
                                                    }
                                                ></TextInput>
                                            </Flex>
                                        </Flex>
                                    ))}
                                </Box>
                            ))}
                        </Box>
                        <Flex justifyContent="center">
                            <Button
                                iconBefore={<Plus />}
                                onClick={() => {
                                    setModalVisible(true);
                                }}
                                variant={ButtonVariants.default}
                            >
                                Add question
                            </Button>
                        </Flex>
                    </MasterCard>
                </PageLayout>
            </Container>
            <ModalAddQuestions visible={modalVisible} onClose={() => setModalVisible(false)} />
        </>
    );
}

const BadgeLine = styled.div`
    background: #f9f9f9;
    text-align: center;
    border: 1px solid #eee;
    padding: 1px;
    border-radius: 10px;
`;
