import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
export default function ({ time }: { time: string }) {
    const [count, setCount] = useState("");

    useEffect(() => {
        const interval = setInterval(() => {
            const diff = moment().diff(time, "seconds");
            const hours = Math.floor(diff / (60 * 60));
            const minutes = Math.floor(diff / 60);
            const seconds = diff % 60;
            setCount(`${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`);
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [time]);

    return <WatchStyled>{count}</WatchStyled>;
}

const WatchStyled = styled.div`
    background: #e1ffe2;
    padding: 10px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    color: #1d9a22;
`;
