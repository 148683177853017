import React from "react";
import { Flex } from "rebass";
import { useHistory } from "react-router-dom";
import Container from "../../components/Container";
import PageLayout from "../../components/layouts/PageLayout";
import CardChecklist from "../../components/Card/CardChecklist";
import { useFetch } from "@fozg/one-point-sdk";
import { OnePointService } from "../../services/OnePointService";
import { OP } from "../../consts/vars";
import StaticCard from "../../components/Card/StaticCard";
import Guide from "../../components/Guide";
import LoadingList from "../../components/LoadingList";

export default function () {
    const history = useHistory();
    const { data, recall, loading } = useFetch(() => OnePointService.getListByName(OP.lists.checklists).getItems());

    return (
        <Container>
            <Guide
                title="Checklist"
                subTitle="Checklist là bộ các câu hỏi được tạo sẵn, phù hợp cho từng ứng viên có vị trí khác nhau. "
                lines={[
                    `Một checklist đầy đủ cần có nhiều câu hỏi thuộc các topic khác nhau. Khi người phỏng vấn đánh giá ứng viên cần đánh giá điểm từng câu trả
                lời của ứng viên và đánh giá chung cho từng topic.`,
                ]}
                actions={[{ title: "Tạo checklist", onClick: () => history.push("/checklist/create") }]}
            />
            <PageLayout pageTitle="Checklist">
                <StaticCard>
                    {loading ? (
                        <LoadingList />
                    ) : (
                        <Flex flexWrap="wrap" margin="0 -10px">
                            {data.map((checklist: Checklist) => (
                                <CardChecklist {...checklist} {...checklist.summary} key={checklist._id} onChange={recall} />
                            ))}
                        </Flex>
                    )}
                </StaticCard>
            </PageLayout>
        </Container>
    );
}
