import React, { useEffect, useState } from "react";
import { Heading, Flex, Box } from "rebass";
import styled from "styled-components";
import { Button, ButtonVariants } from "@fozg/fozg-ui-elements";

import Modal from "../../components/Modal";
import TextInput from "../../components/Forms/TextInput";
import { Search, Plus, Check } from "../../components/Icon";
import Badge from "../../components/Badge";
import IconButton from "../../components/Button/IconButton";
import Rating from "../../components/Rating";
import { OnePointService } from "../../services/OnePointService";
import { OP } from "../../consts/vars";
import useDebounce from "../../hooks/useDebounce";
import { addQuestion, useStore } from "./state.light";

const NUMBER_OF_QUESTIONS_SHOWING = 8;
type Props = {
    onSelected?: void;
    selectedIds?: string[];
    visible?: boolean;
    onClose?: (e: any) => void;
};
export default function ({ selectedIds, visible, onClose }: Props) {
    const [searchQuestion, setSearchQuestion] = useState("");
    const [questions, setQuestions] = useState([]);
    const addedQuestions = useStore((state) => state.questions);

    const debounceSearchQuestion = useDebounce(searchQuestion, 500);

    useEffect(() => {
        if (!debounceSearchQuestion) return;
        OnePointService.getListByName(OP.lists.questions)
            .getItems({
                where: [
                    {
                        question: { $regex: debounceSearchQuestion, $options: "i" },
                    },
                ],
                limit: NUMBER_OF_QUESTIONS_SHOWING,
            })
            .then((res) => {
                if (!res.isError) {
                    setQuestions(res.data);
                }
            });
    }, [debounceSearchQuestion]);

    return (
        <Modal
            visible={visible}
            title={
                <TextInput
                    label=""
                    placeholder="Search question"
                    autoFocus
                    value={searchQuestion}
                    onChange={(e: any) => setSearchQuestion(e.target.value)}
                    prefix={<Search width={23} color={"#999"} />}
                />
            }
            width={600}
            actions={[
                { title: "Close", variant: "primary", onClick: onClose },
                // { title: "Add", variant: "primary", icon: <Check /> },
            ]}
        >
            {questions.length === 0 ? (
                <EnterQuestion>Type something</EnterQuestion>
            ) : (
                questions.map((question: Question, idx) => (
                    <Line key={idx}>
                        <Flex justifyContent="space-between">
                            <div>
                                <Heading fontSize={1} mb={2} fontWeight={500}>
                                    {question.question}
                                </Heading>
                                <Flex>
                                    <Rating rate={question.rate || 1} />
                                    <Box width={2} />
                                    {question.topics && <Badge>{question.topics?.name}</Badge>}
                                </Flex>
                            </div>
                            {addedQuestions.find((q: Question) => q._id === question._id) ? (
                                <Button iconBefore={<Check />} variant={ButtonVariants.success}>
                                    Added
                                </Button>
                            ) : (
                                <IconButton
                                    icon={<Plus />}
                                    variant="outline"
                                    onClick={() => {
                                        addQuestion(question);
                                    }}
                                ></IconButton>
                            )}
                        </Flex>
                    </Line>
                ))
            )}
        </Modal>
    );
}
const Line = styled.div({
    padding: "10px 25px",
    ":hover": {
        background: "#eee",
    },
});

const __mock__ = [
    { question: "What is DOM in React", topic: "React", lvlOfDifficult: 5 },
    {
        question: "What is diffrent between let and const in ES6",
        topic: "Javascript",
        lvlOfDifficult: 1,
    },
    { question: "What is JSX", topic: "React", lvlOfDifficult: 4 },
    {
        question: "Explation about Redux",
        topic: "React",
        lvlOfDifficult: 4,
    },
    { question: "How Redux work", topic: "React", lvlOfDifficult: 3 },
];
const EnterQuestion = styled.div`
    padding: 50px;
    text-align: center;
`;
