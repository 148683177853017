import React, { useCallback, useEffect, useState } from "react";
import { Flex, Text } from "rebass";
import styled from "styled-components";
import { CurentFocusType, setPoint } from "./MatrixMark";

const ModalRate = ({ currentFocus, onClose }: { currentFocus: CurentFocusType; onClose: any }) => {
    const onKeyPress = useCallback(
        (e: KeyboardEvent) => {
            console.log(e.key);
            switch (e.key) {
                case "0":
                    e.preventDefault();
                    setPoint(currentFocus, 0);
                    onClose();
                    break;
                case "1":
                    e.preventDefault();
                    setPoint(currentFocus, 1);
                    onClose();
                    break;
                case "2":
                    e.preventDefault();
                    setPoint(currentFocus, 2);
                    onClose();
                    break;
                case "3":
                    e.preventDefault();
                    setPoint(currentFocus, 3);
                    onClose();
                    break;
                case "4":
                    e.preventDefault();
                    setPoint(currentFocus, 4);
                    onClose();
                    break;
                case "5":
                    e.preventDefault();
                    setPoint(currentFocus, 5);
                    onClose();
                    break;
                case "Escape": {
                    onClose();
                }
            }
        },
        []
    );

    useEffect(() => {
        document.addEventListener("keydown", onKeyPress);
        return () => {
            document.removeEventListener("keydown", onKeyPress);
        };
    }, []);

    return (
        <CustomModalZone>
            <CustomModal>
                <Text textAlign="center">Nhập đánh giá điểm cho câu trả lời</Text>
                <Flex>
                    {[
                        {
                            point: 0,
                            display: "No knowledge/experience",
                        },
                        {
                            point: 1,
                            display: "Fundamental awareness",
                        },
                        {
                            point: 2,
                            display: "Limited experience",
                        },
                        {
                            point: 3,
                            display: "Intermediate",
                        },
                        {
                            point: 4,
                            display: "Advanced",
                        },
                        {
                            point: 5,
                            display: "Expert",
                        },
                    ].map((point, idx) => (
                        <Point key={idx} m={1} onClick={() => {
                            setPoint(currentFocus, idx);
                            onClose();
                        }}>
                            <Text fontSize={18} fontWeight={600}>
                                {point.point}
                            </Text>
                            <Text fontSize={11} fontWeight={500}>
                                {point.display}
                            </Text>
                        </Point>
                    ))}
                </Flex>
                <Text textAlign="center" mt={5} opacity={0.6} fontSize={12}>
                    Có thể dùng chuột hoặc bấm phím từ 0 đến 5
                </Text>
            </CustomModal>
        </CustomModalZone>
    );
};

export default ModalRate;

const CustomModalZone = styled.div`
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 200px;
    z-index: 100;
    // background: #fff;
`;
const CustomModal = styled.div`
    box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.1);

    width: 1000px;
    background: #fff;
    margin: auto;
    padding: 20px;
    height: 200px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`;
const Point = styled(Flex)`
    flex-direction: column;
    flex: 1;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background: #eee;
    border-radius: 10px;

    cursor: pointer;
    :hover {
        background: #dedede;
    }
`;
