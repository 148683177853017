import React from "react";
import styled from "styled-components";
import { Flex, Text } from "rebass";
import { FONT } from "../../utils/theme";

export default function ({
    rate,
    text,
    size = "small",
    disabled = true,
    mute = false,
}: {
    disabled?: boolean;
    rate: number;
    text?: string;
    size?: string;
    mute?: boolean; // make blur
}) {
    const width = (size === "small" ? 16 : 40) * 5;
    return (
        <Flex alignItems="center" opacity={mute ? 0.3 : 1}>
            <Wrapper size={size}>
                <Bg className="bg">
                    <Flex width={width}>
                        {Array(5)
                            .fill(0)
                            .map((_, idx) => (
                                <StarOutline key={idx} />
                            ))}
                    </Flex>
                </Bg>
                <OnTop>
                    <Cover
                        style={{
                            width: `${(rate / 5) * 100}%`,
                        }}
                    >
                        <Flex width={width} flexDirection="row-reverse">
                            {Array(5)
                                .fill(0)
                                .map((_, idx) => (
                                    <StarFilled key={idx} />
                                ))}
                        </Flex>
                    </Cover>
                </OnTop>
            </Wrapper>
            {text && (
                <Text
                    marginLeft={10}
                    fontSize={1}
                    fontFamily={FONT.CascadiaCode}
                >
                    {text}
                </Text>
            )}
        </Flex>
    );
}

const StarOutline = () => (
    <svg viewBox="0 0 20 20" fill="#eee" className="star w-6 h-6">
        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
);
const StarFilled = () => (
    <svg viewBox="0 0 20 20" fill="#000" className="star w-6 h-6">
        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
);

const sizeMap = (size: "small" | "large") =>
    ({
        small: {
            width: 18,
            height: 20,
        },
        large: {
            width: 40,
            height: 40,
        },
    }[size]);
const Wrapper = styled.div<{ size: any }>(
    {
        position: "relative",
        ".star": {
            marginRight: 2,
            marginBottom: 3,
        },
        display: "inline-block",
        ":hover": {
            ".bg": {
                zIndex: 5,
            },
        },
    },
    (props) => ({ ".star": sizeMap(props.size) })
);

const Bg = styled.div({
    zIndex: 1,
});
const OnTop = styled.div({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    // overflow: "hidden",
});
const Cover = styled.div({
    overflow: "hidden",
});
