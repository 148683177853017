import * as React from "react";
import { useFetch } from "@fozg/one-point-sdk";
import { OnePointService } from "../../services/OnePointService";
import { OP } from "../../consts/vars";
import CardChecklist from "../../components/Card/CardChecklist";

const NUMBER_OF_CHECKLIST_SHOWING_ON_HOMEPAGE = 3;
type Props = {
    headerShowed?: boolean;
};
export default function ({ headerShowed = true }: Props) {
    const { data } = useFetch(() => OnePointService.getListByName(OP.lists.checklists).getItems({ limit: NUMBER_OF_CHECKLIST_SHOWING_ON_HOMEPAGE }));

    return (
        <>
            {data.map((item: Checklist) => (
                <CardChecklist {...item} {...item.summary} key={item._id} dropShadow={false} />
            ))}
        </>
    );
}
