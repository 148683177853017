import { COLORS } from "../../utils/theme";

export default {
    theme: (theme: any) => ({
        ...theme,
        colors: { ...theme.colors, primary: COLORS.MAIN },
        borderRadius: 8,
    }),
    styles: {
        control: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: "#eee",
            borderColor: state.isFocused ? COLORS.MAIN : "transparent",
            height: 40,
        }),
    },
};
