import React from "react";
import styled from "styled-components";
import { Box, Flex } from "rebass";
import { FiMoreHorizontal } from "react-icons/fi";
import { Button, ButtonVariants } from "@fozg/fozg-ui-elements";
import ActionMenu, { ActionProps } from "../ActionMenu";

export type BaseCardActions = {
    title: string;
    onClick: () => void;
    variant: ButtonVariants;
};
type Props = {
    borderHoverWidth?: number;
    borderHoverColor?: string;
    backgroundColor?: string;
    children?: any;
    width?: number | number[];
    marginRight?: number | number[];
    dropShadow?: boolean;
    moreActions?: ActionProps[];
    actions?: BaseCardActions[];
};

export default function ({ borderHoverColor, borderHoverWidth, backgroundColor, children, width, marginRight, dropShadow, moreActions, actions = [] }: Props) {
    return (
        <Box padding={10} width={width}>
            <Wrapper borderHoverColor={borderHoverColor} borderHoverWidth={borderHoverWidth} backgroundColor={backgroundColor} dropShadow={dropShadow}>
                <GroupAction>
                    <Flex>
                        {actions.map((action) => (
                            <Button onClick={action.onClick} variant={action.variant} key={action.title}>
                                {action.title}
                            </Button>
                        ))}

                        {moreActions && (
                            <ActionMenu actions={moreActions}>
                                <Button variant={ButtonVariants.outline}>
                                    <FiMoreHorizontal size={20} />
                                </Button>
                            </ActionMenu>
                        )}
                    </Flex>
                </GroupAction>
                {children}
            </Wrapper>
        </Box>
    );
}

type WrapperProps = {
    borderHoverColor: any;
    borderHoverWidth: any;
    dropShadow?: boolean;
};
export const Wrapper = styled(Box)<WrapperProps>(
    {
        padding: 20,
        border: "1px solid #ddd",
        display: "inline-block",
        borderRadius: 15,
        position: "relative",
        width: "100%",
    },
    ({ borderHoverColor, borderHoverWidth }) =>
        borderHoverColor
            ? {
                  ":hover": {
                      borderColor: borderHoverColor,
                      boxShadow: `inset 0 0 0 ${borderHoverWidth - 1}px ${borderHoverColor}, 0 10px 20px rgba(0,0,0,.1)`,
                  },
              }
            : {
                  ":hover": {
                      borderColor: "#ddd",
                      boxShadow: `0 10px 15px rgba(0,0,0,.1)`,
                  },
              },
    ({ dropShadow }) => (dropShadow ? { boxShadow: `0 10px 15px rgba(0,0,0,.1)` } : null)
);
const GroupAction = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
`;
