import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box, Flex, Text } from "rebass";
import toast from "react-hot-toast";
import PageLayout from "../../../components/layouts/PageLayout";
import Container from "../../../components/Container";
import { Check } from "../../../components/Icon";
import MasterCard from "../../../components/layouts/MasterCard";
import TextInput from "../../../components/Forms/TextInput";
import TextareaInput from "../../../components/Forms/TextareaInput";
import RatingAction from "../../../components/Rating/RatingAction";
import SelectOP from "../../../components/Select/SelectOP";
import { OP } from "../../../consts/vars";
import { OnePointService } from "../../../services/OnePointService";

const anwserTemplate = `The answer should be: 
...

Rate 5 point:
...

Rate 4 point:
...

Rate 3 point:
...

Rate 2 point:
...

Rate 1 point:
...
`;

export default function () {
    const history = useHistory();
    const param = useParams<{ id: string }>();
    const [topic, setTopic] = useState<{ value: string; label: string }>();
    const [question, setQuestion] = useState();
    const [answer, setAnswer] = useState(anwserTemplate);
    const [rate, setdifficult] = useState(1);
    const [title, setTitle] = useState("Create a question");

    useEffect(() => {
        if (param.id) {
            OnePointService.getListByName(OP.lists.questions)
                .getItemById(param.id)
                .then((res) => {
                    if (!res.isError) {
                        setTitle(res.data.question);
                        setQuestion(res.data.question);
                        setAnswer(res.data.answer);
                        setdifficult(res.data.rate);
                    }
                    if (res.data.topics_id) {
                        OnePointService.getListByName(OP.lists.topics)
                            .getItemById(res.data.topics_id)
                            .then((res) => {
                                setTopic({
                                    value: res.data._id,
                                    label: res.data.name,
                                });
                            });
                    }
                });
        }
    }, [param.id]);

    const onSave = () => {
        OnePointService.getListByName(OP.lists.questions)
            .addItem({
                topics_id: topic?.value,
                question,
                answer,
                rate,
            })
            .then((res) => {
                if (res.isError) {
                    toast.error(res.message);
                } else {
                    toast.success("Question created");
                    history.push("/question");
                }
            });
    };

    return (
        <Container>
            <PageLayout
                pageTitle={title}
                actions={[
                    {
                        title: "Save",
                        icon: <Check />,
                        // variant: "success",
                        onClick: onSave,
                    },
                ]}
            >
                <MasterCard>
                    <Flex justifyContent="space-between">
                        <Box width="50%">
                            <SelectOP
                                label="Topic"
                                filter={{
                                    listName: OP.lists.topics,
                                    lookupField: "name",
                                }}
                                onChange={(t: any) => {
                                    setTopic(t);
                                    console.log(t);
                                }}
                                value="a"
                                mb={3}
                                selectedValue={topic}
                            />
                        </Box>
                        <div>
                            <Text fontWeight={500} fontSize={13}>
                                Level of difficulty
                            </Text>
                            <RatingAction
                                rate={rate}
                                size="large"
                                onChange={(idx) => setdifficult(idx)}
                            />
                        </div>
                    </Flex>

                    <TextInput
                        label="Question"
                        placeholder="Full question go here"
                        value={question}
                        onChange={(e: any) => setQuestion(e.target.value)}
                    />
                    <TextareaInput
                        label="Suggestion answer"
                        minHeight="50vh"
                        value={answer}
                        onChange={(e: any) => setAnswer(e.target.value)}
                    ></TextareaInput>
                </MasterCard>
            </PageLayout>
        </Container>
    );
}
