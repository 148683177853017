import React from "react";
import { Box, Flex } from "rebass";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import Container from "../Container";
import { FiPlay } from "react-icons/fi";

export default () => (
    <Wrapper>
        <Bg></Bg>
        <Menu>
            <Container>
                <Flex py={4}>
                    <StyledLink exact to="/" activeClassName="active">
                        Trang chủ
                    </StyledLink>
                    <StyledLink to="/question" activeClassName="active">
                        Câu hỏi
                    </StyledLink>
                    <StyledLink to="/checklist" activeClassName="active">
                        Checklist
                    </StyledLink>
                    <StyledLink to="/section" activeClassName="active">
                        Buổi phỏng vấn
                    </StyledLink>
                    <StyledLink to="/result" activeClassName="active">
                        Kết quả phỏng vấn
                    </StyledLink>
                    {/* <ProcessingButton to="/processing/:sectionId" activeClassName="active">
                        <FiPlay /> Đang phỏng vấn
                    </ProcessingButton> */}
                </Flex>
            </Container>
        </Menu>
    </Wrapper>
);

const Bg = styled.div`
    ::after {
        height: 40vh;
        width: 100%;
        content: close-quote;
        background-image: url(https://images.unsplash.com/photo-1492944557828-11e576351057?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1600&q=80);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: absolute;
        z-index: -1;
    }
`;
const Wrapper = styled.div`
    // background: #2f3032;
    // height: 300px;
    .active {
        background-color: rgba(0, 0, 0, 0.6);
        color: #fff;
    }
`;
const Menu = styled.div`
    background: transparent;
`;
const StyledLink = styled(NavLink)`
    font-size: 18px;
    color: #ccc;
    font-weight: 600;
    padding: 5px 15px;
    background-color: rgba(0, 0, 0, 0.26);
    // border-radius: 8px;
    :hover {
        background-color: rgba(0, 0, 0, 0.26);
    }
    :active {
        color: #fff;
    }
`;
const ProcessingButton = styled(NavLink)`
    color: #fff;
    border: 2px solid #eee;
    border-radius: 10px;
    align-items: center;
    padding: 5px 10px;
    display: flex;
`;
