import React from "react";
import BaseCard from "./BaseCard";
import { FiEdit2, FiTrash } from "react-icons/fi";
import { confirm, confirmTypes } from "@fozg/fozg-ui-elements";
import { Text, Box } from "rebass";
import Rating from "../Rating";
import { OnePointService } from "../../services/OnePointService";
import { OP } from "../../consts/vars";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

type Props = {
    _id: string;
    title: string;
    description?: string;
    numberOfQuiz: number;
    numberOfTopic: number;
    createdBy: string;
    matrix: number[];
    width?: number | number[] | any;
    dropShadow?: boolean;
    onChange?: () => void;
    actionsShowed?: boolean;
};

export default function ({
    _id,
    title,
    description,
    numberOfQuiz,
    numberOfTopic,
    createdBy,
    matrix = [],
    width = [1, 1, 1 / 3],
    dropShadow,
    onChange = () => {},
    actionsShowed = true,
}: Props) {
    const history = useHistory();
    return (
        <BaseCard
            borderHoverColor={"#14B1AB"}
            borderHoverWidth={2}
            width={width}
            backgroundColor="#fff"
            marginRight={[0, 20, 30]}
            dropShadow={dropShadow}
            moreActions={
                actionsShowed
                    ? [
                          {
                              title: "Sửa",
                              icon: <FiEdit2 />,
                              onClick: () => {
                                  history.push(`/checklist/${_id}`)
                              },
                          },
                          {
                              title: "Delete",
                              icon: <FiTrash />,
                              onClick: () => {
                                  deleteChecklist(_id, onChange);
                              },
                          },
                      ]
                    : undefined
            }
        >
            <Text fontSize={1}>
                {createdBy}
            </Text>
            <Text fontWeight="600" fontSize={3} color="#14B1AB">
                {title}
            </Text>
            <Text fontSize={1}>
                {numberOfQuiz} quiz / {numberOfTopic} topics
            </Text>
            <Box height={30} />
            {matrix.map((count, idx) => (
                <Box key={idx}>
                    <Rating rate={5 - idx} text={`${count} quiz`} mute={!count} />
                </Box>
            ))}
        </BaseCard>
    );
}

function deleteChecklist(checklistId: string, cb: () => void) {
    confirm({
        title: "Xóa checklist",
        type: confirmTypes.danger,
        message: "Sau khi xóa Checklist sẽ không thể khôi phục, bạn muốn tiếp tục chứ? ",
        primaryButton: "Xóa",
        onOk: () => {
            OnePointService.getListByName(OP.lists.checklists)
                .deleteItem(checklistId)
                .then((res) => {
                    if (res.isError) {
                        toast.error(res.message);
                    } else {
                        toast.success("Đã xóa checklist");
                        cb();
                    }
                });
        },
    });
}
