import React from "react";
import { Button, ButtonProps, Flex } from "rebass";

type Props = {
    icon?: any;
} & ButtonProps;
export default function ({ icon, children, ...rest }: Props) {
    return (
        <Button {...rest} className="button">
            <Flex alignItems="center">
                {icon}
                <span>{children}</span>
            </Flex>
        </Button>
    );
}
