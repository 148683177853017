import LightState from "react-light-state";

const internalState = new LightState<{ title: string; description: string; questions: Question[] }>({
    title: "",
    description: "",
    questions: [],
});
const { setState, dispatch, useStore, getState } = internalState;
export { setState, useStore };


export function addQuestion(question: Question) {
    setState((data) => ({
        questions: data.questions.concat({
            ...question,
            weight: 0,
        }),
    }));
}

export function updateWeight(qId: string, weight: string) {
    setState((pre) => {
        const questions = [...pre.questions];
        const findIdx = questions.findIndex((q) => q._id === qId);
        questions[findIdx].weight = parseFloat(weight);
        return { questions };
    });
}
