import React from "react";
import LogoYellowSvg from "../svgs/LogoYellow.svg";
// import LogoGreenSvg from '../svgs/LogoGreen.svg';
// import LogoBlueSvg from '../svgs/LogoBlue.svg';
// import LogoPinkSvg from '../svgs/LogoPink.svg';
import styled from "@emotion/styled";
import { Flex } from "rebass";

type Props = {
    width?: number;
    height?: number;
};

export default function ({ width = 200, height = 200 }: Props) {
    return (
        <Wrapper height={height} width={width}>
            <LogoYellowSvg className=" item" />
            {/* <LogoBlueSvg className="fade fade-2 item" />
      <LogoPinkSvg className="fade fade-3 item" />
      <LogoGreenSvg className="fade fade-4 item" /> */}
        </Wrapper>
    );
}

const Wrapper = styled(Flex)({
    position: "relative",
    ".item": {
        position: "absolute",
        width: "100%",
        height: "100%",
    },
});
