import React, { useEffect } from "react";
import { useFetch } from "@fozg/one-point-sdk";
import marked from "marked";
import { useParams } from "react-router-dom";
import { ButtonVariants, confirm, confirmTypes } from "@fozg/fozg-ui-elements";
import { FiUserPlus, FiLogOut } from "react-icons/fi";
import styled from "@emotion/styled";
import { Box, Flex, Text } from "rebass";
import StaticCard from "../../components/Card/StaticCard";
import Container from "../../components/Container";
import Guide from "../../components/Guide";
import { OP } from "../../consts/vars";
import { OnePointService } from "../../services/OnePointService";
import MatrixMark, { setState } from "./MatrixMark";
import { useStore, getState } from "./MatrixMark";
import StopWatch from "../../components/StopWatch";
import toast from "react-hot-toast";

export default function () {
    const { sectionId } = useParams<{ sectionId: string }>();
    const checklist = useStore((state) => state.checklist);
    const showingAnswer = useStore((state) => state.showingAnswer);

    const { data, loading, setData }: { data: InterviewSection; loading: boolean; setData: any } = useFetch(() =>
        OnePointService.getListByName(OP.lists.sections).getItemById(sectionId)
    );

    useEffect(() => {
        if (data._id) {
            if (data.started_time === undefined) {
                OnePointService.getListByName(OP.lists.sections)
                    .updateItem(data._id, { started_time: new Date().toISOString() })
                    .then(() => {
                        setData({ ...data, started_time: new Date().toISOString() });
                    });
            }
        }
    }, [data]);

    const onEndClick = () => {
        confirm({
            title: "Kết thúc phỏng vấn",
            message: "Chắc chưa 😉",
            type: confirmTypes.warning,
            onOk: () => {
                OnePointService.getListByName(OP.lists.sections).updateItem(data._id, { end_time: new Date().toISOString() });
                const interviewResult = getState();
                OnePointService.getListByName(OP.lists.results)
                    .addItem({
                        sections_id: sectionId,
                        topics: interviewResult.topics,
                        points: interviewResult.points,
                        session: data,
                        checklist: interviewResult.checklist,
                    })
                    .then((res) => {
                        if (res.isError) {
                            toast.error("Lỗi gì ý khi save kết quả");
                        } else {
                            toast.success("Save kết quả thành công");
                        }
                    });
            },
        });
    };

    return (
        <>
            <Container>
                <Guide
                    width="100%"
                    title="Đang phỏng vấn"
                    subTitle={data.name + " - " + data.job_title}
                    lines={[`Checklist: ${checklist?.title} - ${checklist?.description}`, `HR Note: `]}
                    actions={[
                        { title: "Mời người phỏng vấn", variant: ButtonVariants.default, iconBefore: <FiUserPlus /> },
                        { title: "Kết thúc phỏng vấn", variant: ButtonVariants.danger, iconBefore: <FiLogOut />, onClick: onEndClick },
                    ]}
                    rightPanel={
                        <>
                            <StopWatch time={data.started_time} />
                        </>
                    }
                ></Guide>
            </Container>
            <Flex mt={4} mx={[1, 2, 3, 4, 5]} flexDirection="column">
                {showingAnswer && (
                    <Sticky>
                        {/* <StaticCard width={500} mr={3}> */}
                        <Text fontSize={15} fontWeight={600} mb={2}>
                            {showingAnswer.question}
                        </Text>
                        <Answer dangerouslySetInnerHTML={{ __html: marked(showingAnswer.answer) }}></Answer>
                        {/* </StaticCard> */}
                    </Sticky>
                )}
                <StaticCard width="100%">{data.checklists_id && <MatrixMark checklistId={data.checklists_id} />}</StaticCard>
            </Flex>
            <Box mt={2} mx={5}>
                <Text opacity={0.8} textAlign="center">
                    Sử dụng phím mũi tên để di chuyển giữa các ô, ấn Enter để xem câu hỏi hoặc để cho điểm, nhận xét.
                </Text>
            </Box>
        </>
    );
}

const Fly = styled.div`
    position: fixed;

    left: 20px;
    // bottom: 20px;
    top: 80px;
    overflow-y: auto;
    border-radius: 10px;
    background: #fff;
    padding: 20px;
    width: 500px;
    max-height: 600px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.09);
    z-index: 101;
`;

const Sticky = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    overflow-y: auto;
    // border-radius: 10px;
    background: #fff;
    padding: 20px;
    max-width: 500px;
    width: 30%;
    // max-height: 600px;
    border-left: 1px solid #eee;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.09);
    z-index: 101;
`;

const Answer = styled.div`
    font-size: 14px;

    pre {
        background: #f1f1f1;
        padding: 10px;
        border-radius: 10px;
    }
`;
