import React from "react";
import AsyncSelect from "react-select/async";
import { Text, Box, BoxProps } from "rebass";
import { OnePointService } from "../../services/OnePointService";
import customStyles from "./customStyles";

type Props = {
    label: string;
    filter: {
        listName: string;
        lookupField: string;
    };
    onChange: (value: any) => void;
    selectedValue?: {
        label: string;
        value: any;
    };
};

export default function ({ label, filter, onChange, selectedValue, ...rest }: Props & BoxProps) {
    const loadOptions = (inputValue: string, callback: (options: any[]) => void) => {
        OnePointService.getListByName(filter.listName)
            .getItems({
                where: [
                    {
                        [filter.lookupField]: { $regex: inputValue, $options: "i" },
                    },
                ],
            })
            .then((res) => {
                console.log(res);
                callback(
                    res.data.map((item: any) => ({
                        value: item._id,
                        label: item[filter.lookupField],
                    }))
                );
            });
    };

    return (
        <Box {...rest}>
            <Text variant="label" mb={2}>
                {label}
            </Text>
            <AsyncSelect cacheOptions {...customStyles} loadOptions={loadOptions} onChange={onChange} value={selectedValue}></AsyncSelect>
        </Box>
    );
}
