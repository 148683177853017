import React from "react";
import styled, { keyframes, css } from "styled-components";
import Container from "../Container";

import useScrollToTop from "../../hooks/useScrollToTop";
import Footer from "./Footer";
export default function ({ children }: any) {
    useScrollToTop();

    return (
        <>
            <Wrapper>{children}</Wrapper>
            <Footer />
        </>
    );
}

const Wrapper = styled.div`
    min-height: calc(100vh - 300px);
`;
