import React, { useRef, useState } from "react";
import { Flex, Box } from "rebass";
import styled from "styled-components";
import useOnClickOutside from "../../hooks/useClickoutside";

type Props = {
    children: React.ReactChild;
    actions: ActionProps[];
};
export type ActionProps = {
    title: string;
    onClick: () => void;
    icon?: any;
};
export default function ({ children, actions = [] }: Props) {
    const [visible, setVisible] = useState(false);
    const ref = useRef(null);
    useOnClickOutside(ref, () => setVisible(false));

    return (
        <Wrapper
            onClick={(e) => {
                setVisible(true);
            }}
        >
            {children}
            {visible && (
                <ActionMenu ref={ref}>
                    {actions.map((action, idx) => (
                        <ActionItem
                            p={2}
                            key={idx}
                            alignItems="center"
                            onClick={(e) => {
                                e.stopPropagation();
                                setVisible(false);
                                action.onClick();
                            }}
                        >
                            <Flex width={'auto'} justifyContent="flex-end" mr={2}>
                                {action.icon}
                            </Flex>
                            {action.title}
                        </ActionItem>
                    ))}
                </ActionMenu>
            )}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    position: relative;
`;
const ActionMenu = styled.div`
    position: absolute;
    right: 0;
    top: 100%;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    min-width: 100px;
    background: #fff;
    border: 1px solid #eee;
    padding: 5px;
`;
const ActionItem = styled(Flex)`
    font-weight: 500;
    border-radius: 5px;
    :hover {
        cursor: pointer;
        background: #f1f1f1;
    }
`;
