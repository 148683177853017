import React from "react";
import { Box, Flex } from "rebass";
import styled from "styled-components";

const STEPS = ["DEV 1", "DEV 2", "DEV 3", "DEV 4", "DEV 5", "DEV 6"];
// const STEPS_POINT = [2.5, 3, 4, 5, 5.4, 6];
const STEPS_POINT = [2.5, 0.5, 1, 1, 1.4, 0.6];

type Props = {
    width?: number;
    point?: number;
};
export default function ({ width = 300, point = 99 }: Props) {
    const total = STEPS_POINT.reduce((a, b) => a + b, 0);

    return (
        <>
            <StepRelative width={width}>
                <Flex flex={1}>
                    {STEPS_POINT.map((step: any, idx: number) => (
                        <Step width={`${(step / total) * 100}%`} backgroundColor={`rgba(255,87,34, ${((idx + 1) * 1.6) / 10 + ""})`}></Step>
                    ))}
                </Flex>
                <Point style={{ left: (point / total) * 100 + "%" }}>
                    <Line></Line>
                    <PointText>{findStepBelong(point)}</PointText>
                </Point>
            </StepRelative>
        </>
    );
}

function findStepBelong(point: number) {
    var tt = 0;
    for (var i = 0; i < STEPS_POINT.length; i++) {
        tt += STEPS_POINT[i];
        if (point < tt) {
            return STEPS[i];
        }
    }
}

const StepRelative = styled(Flex)`
    position: relative;
    height: 10px;
    border-radius: 10px;
`;
const Step = styled(Box)`
    height: 13px;
    :first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    :last-of-type {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;
const Point = styled(Box)`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateX(-50%);
    transition: left 0.5s;
`;
const Line = styled.div`
    opacity: 0.5;
    height: 18px;
    width: 1px;
    border-left: 1px solid #000;
`;
const PointText = styled.div`
    background: #111;
    border-radius: 5px;
    padding: 5px;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
`;
