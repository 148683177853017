import React, { ReactChild, useCallback, useEffect, useState } from "react";
import marked from "marked";
import { chain } from "lodash";
import toast from "react-hot-toast";
import LightState from "react-light-state";
import styled from "styled-components";
import Badge from "../../components/Badge";
import { OP } from "../../consts/vars";
import { OnePointService } from "../../services/OnePointService";
import { Box, Flex, Text } from "rebass";
import ModalInput from "./ModalInput";
import ModalRate from "./ModalRate";
import { defaultQuestions, defaultRating } from "./defaultQuestions";
import { List } from "react-content-loader";
import TextareaInput from "../../components/Forms/TextareaInput";
import TextareaAutosize from "react-textarea-autosize";

(window as any).appendString = "";
export type PointState = {
    commentByTopic: string;
    byQuestions: {
        point?: number;
        commentByQuestion: string;
    }[];
};
const markState = new LightState<{
    checklist?: Checklist;
    topics: { topic: string; questions: Question[] }[];
    points: PointState[];
    showingAnswer?: Question;
}>(
    {
        topics: [],
        points: [],
        checklist: undefined,
    },
    "markstate"
);
export const { setState, useStore, getState } = markState;

export function getValueByPosition(t: number, x: number, y: number): string | number {
    const points = getState("points") as {
        commentByTopic: string;
        byQuestions: {
            point?: number;
            commentByQuestion: string;
        }[];
    }[];

    if (y === 3) {
        return points[t].commentByTopic;
    }
    if (y === 2) {
        return points[t].byQuestions[x].commentByQuestion;
    }
    if (y === 1) {
        return points[t].byQuestions[x].point as number;
    }
    return "";
}
export function clearData() {
    setState({
        topics: [],
        points: [],
        checklist: undefined,
    });
}
export function setPoint(focusPosition: CurentFocusType, value: string | number) {
    if (focusPosition.y === 1) {
        setState((pre) => {
            const newPoint = [...pre.points];
            newPoint[focusPosition.t].byQuestions[focusPosition.x].point = value as number;
            return { points: newPoint };
        });
    } else if (focusPosition.y === 2) {
        setState((pre) => {
            const newPoint = [...pre.points];
            newPoint[focusPosition.t].byQuestions[focusPosition.x].commentByQuestion = value.toString();
            return { points: newPoint };
        });
    } else if (focusPosition.y === 3) {
        setState((pre) => {
            const newPoint = [...pre.points];
            newPoint[focusPosition.t].commentByTopic = value.toString();
            return { points: newPoint };
        });
    }
}

type Props = {
    checklistId?: string;
    disable?: boolean;
};
export type FocusType = { t: number; x: number[]; y: number };
export type CurentFocusType = { t: number; x: number; y: number };
export default function ({ checklistId, disable }: Props) {
    const { topics, points } = useStore((state) => state);
    const [currentFocus, setFocus] = useState<CurentFocusType>({ t: disable ? -1 : 0, x: 0, y: 0 });
    const [isEnter, setIsEnter] = useState<false | "comment" | "rate">(false);
    const showingAnswer = useStore((state) => state.showingAnswer);

    useEffect(() => {
        if (checklistId) {
            OnePointService.getListByName(OP.lists.checklists)
                .getItemById(checklistId)
                .then((res) => {
                    if (res.isError) {
                        toast.error("Something went wrong");
                    } else {
                        const groupByTopic = chain(res.data.questions)
                            .groupBy("topics.name")
                            .map((value, key) => ({
                                topic: key,
                                questions: value,
                            }))
                            .value();
                        setState({
                            checklist: res.data,
                            topics: groupByTopic.concat(defaultQuestions),
                            points: groupByTopic
                                .map((item, idx) => ({
                                    commentByTopic: "",
                                    byQuestions: item.questions.map((q) => ({
                                        point: undefined,
                                        commentByQuestion: "",
                                    })),
                                }))
                                .concat(defaultRating),
                        });
                    }
                });
        }
    }, []);

    const onModalInputClose = () => {
        setIsEnter(false);
        document.addEventListener("keydown", onKeyDown);
    };

    const onKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (disable) return;
            switch (e.key) {
                case "Enter":
                    // if (currentFocus.y > 1) e.preventDefault();
                    if (currentFocus.y === 0) {
                        setState((state) => ({ showingAnswer: state.topics[currentFocus.t].questions[currentFocus.x] }));
                    } else {
                        setIsEnter(currentFocus.y === 1 ? "rate" : false); //);
                        // document.removeEventListener("keydown", onKeyDown);
                    }
                    break;
                case "Escape":
                    if (getState("showingAnswer")) {
                        setState({ showingAnswer: undefined });
                    }
                    break;
                case "ArrowLeft":
                    if (e.shiftKey || e.ctrlKey) return;
                    e.preventDefault();
                    setFocus((pre) => {
                        if (pre.y === 0) return pre;
                        return {
                            x: pre.x,
                            y: pre.y - 1,
                            t: pre.t,
                        };
                    });
                    break;
                case "ArrowRight":
                    e.preventDefault();
                    setFocus((pre) => {
                        if (pre.y === 3) return pre;
                        return {
                            x: pre.x,
                            y: pre.y + 1,
                            t: pre.t,
                        };
                    });
                    break;
                case "ArrowUp":
                    if (e.shiftKey) {
                        window.scrollBy(0, -50);
                        break;
                    }
                    e.preventDefault();

                    if (currentFocus.x === 0 && currentFocus.t === 0) return;
                    const newFocus = {
                        x: currentFocus.x === 0 ? topics[currentFocus.t - 1].questions.length - 1 : currentFocus.x - 1,
                        y: currentFocus.y,
                        t: currentFocus.x === 0 ? currentFocus.t - 1 : currentFocus.t,
                    };
                    setFocus(newFocus);
                    if (getState("showingAnswer")) {
                        setTimeout(() => {
                            setState((state) => ({ showingAnswer: state.topics[newFocus.t].questions[newFocus.x] }));
                        });
                    }
                    break;
                case "ArrowDown":
                    if (e.shiftKey) {
                        window.scrollBy(0, 50);
                        break;
                    }

                    e.preventDefault();
                    if (currentFocus.x === topics[currentFocus.t].questions.length - 1 && currentFocus.t === topics.length - 1) return;
                    const newFocusDown =
                        currentFocus.y === 3
                            ? {
                                  x: 0,
                                  y: 3,
                                  t: currentFocus.t + 1,
                              }
                            : {
                                  x: topics[currentFocus.t].questions.length - 1 === currentFocus.x ? 0 : currentFocus.x + 1,
                                  y: currentFocus.y,
                                  t: topics[currentFocus.t].questions.length - 1 === currentFocus.x ? currentFocus.t + 1 : currentFocus.t,
                              };

                    setFocus(newFocusDown);
                    if (getState("showingAnswer")) {
                        setTimeout(() => {
                            setState((state) => ({ showingAnswer: state.topics[newFocusDown.t].questions[newFocusDown.x] }));
                        });
                    }
                    break;
            }
            if ((e.keyCode >= 65 && e.keyCode <= 90) || e.keyCode === 32) {
                // if (currentFocus.y < 2) return;
                // e.preventDefault();
                // (window as any).appendString = e.key.toString();
                // if (currentFocus.y === 0) {
                //     setState((state) => ({ showingAnswer: state.topics[currentFocus.t].questions[currentFocus.x] }));
                // } else {
                //     setIsEnter(currentFocus.y === 1 ? "rate" : "comment");
                //     document.removeEventListener("keydown", onKeyDown);
                // }
            } else if (e.keyCode > 47 && e.keyCode < 54) {
                if (currentFocus.y !== 1) return;
                setPoint(currentFocus, e.key.toString());
            } else {
                (window as any).appendString = "";
            }
        },
        [topics, currentFocus]
    );

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown);
        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [topics, currentFocus]);

    if (topics.length === 0) {
        return (
            <Box width="50%">
                <List />
            </Box>
        );
    }

    return (
        <>
            <HightlineControl>
                <TableHeading>
                    <Flex flex={2}>
                        <Flex width={"30vw"} justifyContent="center">
                            Câu hỏi
                        </Flex>
                        <Flex width={60} ml={2} justifyContent="center">
                            Điểm
                        </Flex>
                        <Flex flex={1} ml={2} justifyContent="center">
                            Nhận xét / Trả lời của ứng viên
                        </Flex>
                    </Flex>
                    <Flex flex={1} ml={2} mb="4px" minWidth={300} justifyContent="center">
                        Nhận xét theo topic
                    </Flex>
                </TableHeading>
                <div className={isEnter ? "enter" : ""}>
                    {topics.map((item, idx) => (
                        <TopicGroup key={idx} mb={2}>
                            <Box mb={2}>
                                <Badge>{item.topic !== "undefined" ? item.topic : "Non topic"}</Badge>
                            </Box>
                            <Flex>
                                <Flex flex={3}>
                                    <Box width={"100%"}>
                                        {item.questions.map((question, jdx) => (
                                            <QuestionGroup mb={1} key={jdx}>
                                                <Flex width={"30vw"}>
                                                    <FocusCell
                                                        isFocus={isFocus(currentFocus, { t: idx, x: [jdx], y: 0 })}
                                                        onClick={() => {
                                                            setFocus({ t: idx, x: jdx, y: 0 });
                                                        }}
                                                    >
                                                        {question.question}
                                                    </FocusCell>
                                                </Flex>
                                                <Flex width={80} ml={1}>
                                                    <FocusCell
                                                        isFocus={isFocus(currentFocus, { t: idx, x: [jdx], y: 1 })}
                                                        onClick={() => {
                                                            setFocus({ t: idx, x: jdx, y: 1 });
                                                        }}
                                                    >
                                                        <Flex alignItems="center">
                                                            <Text textAlign="center" fontWeight="600">
                                                                {points[idx].byQuestions[jdx].point}
                                                            </Text>
                                                            <Text ml={1} textAlign="center" fontSize={"7px"} fontWeight={500}>
                                                                {getPointText(points[idx].byQuestions[jdx].point)}
                                                            </Text>
                                                        </Flex>
                                                    </FocusCell>
                                                </Flex>
                                                {/* <Text color="red">{question.weight}%</Text> */}
                                                <Flex flex={1} ml={1}>
                                                    <FocusCell
                                                        isFocus={isFocus(currentFocus, { t: idx, x: [jdx], y: 2 })}
                                                        onClick={() => {
                                                            setFocus({ t: idx, x: jdx, y: 2 });
                                                        }}
                                                    >
                                                        {currentFocus.t === idx && currentFocus.x === jdx && currentFocus.y === 2 ? (
                                                            <InputMagic
                                                                onChange={(value: string) => {
                                                                    setPoint(currentFocus, value);
                                                                }}
                                                                value={points[idx].byQuestions[jdx].commentByQuestion}
                                                            />
                                                        ) : (
                                                            points[idx].byQuestions[jdx].commentByQuestion
                                                        )}
                                                    </FocusCell>
                                                </Flex>
                                            </QuestionGroup>
                                        ))}
                                    </Box>
                                </Flex>
                                <Flex flex={1} ml={2} mb="4px" minWidth={200}>
                                    <FocusCell
                                        isFocus={isFocus(currentFocus, { t: idx, x: fillArray(item.questions.length), y: 3 })}
                                        onClick={() => {
                                            setFocus({ t: idx, x: 0, y: 3 });
                                        }}
                                    >
                                        {isFocus(currentFocus, { t: idx, x: fillArray(item.questions.length), y: 3 }) ? (
                                            <InputMagic
                                                onChange={(value: string) => {
                                                    setPoint(currentFocus, value);
                                                }}
                                                value={points[idx].commentByTopic}
                                            />
                                        ) : (
                                            points[idx].commentByTopic
                                        )}
                                    </FocusCell>
                                </Flex>
                            </Flex>
                        </TopicGroup>
                    ))}
                </div>
            </HightlineControl>
            {isEnter === "comment" && <ModalInput currentFocus={currentFocus} onClose={onModalInputClose} />}
            {isEnter === "rate" && <ModalRate currentFocus={currentFocus} onClose={onModalInputClose} />}
        </>
    );
}

function getPointText(point?: number): string {
    if (point !== undefined) {
        return [
            {
                point: 0,
                display: "No knowledge\nor experience",
            },
            {
                point: 1,
                display: "Fundamental awareness",
            },
            {
                point: 2,
                display: "Limited experience",
            },
            {
                point: 3,
                display: "Intermediate",
            },
            {
                point: 4,
                display: "Advanced",
            },
            {
                point: 5,
                display: "Expert",
            },
        ][point].display;
    }
    return "";
}

const FocusCell: React.FC<{ isFocus: boolean; onClick: any }> = ({ children, isFocus, onClick }) => {
    return (
        <FocusCellStyled isFocus={isFocus} className={isFocus ? "focus" : ""} onClick={onClick}>
            {children}
        </FocusCellStyled>
    );
};

const InputMagic: React.FC<{ value?: string; onChange?: any }> = ({ value, onChange }) => {
    const [_v, setV] = useState(value);
    const _onChange = (e: any) => {
        onChange(e.target.value);
        setV(e.target.value);
    };
    return <Input autoFocus autoCorrect="false" spellCheck="false" onBlur={(e) => {}} value={_v} onChange={_onChange}></Input>;
};

const isFocus = (currentFocus: CurentFocusType, focusPosition: FocusType) =>
    currentFocus.t === focusPosition.t && currentFocus.y === focusPosition.y && focusPosition.x.includes(currentFocus.x);
const Input = styled(TextareaAutosize)`
    background: transparent;
    width: 100%;
    border: none;
    outline: none;
    font-family: "Segoe UI";
    font-size: 15px;
    margin: -4px 0;
    height: 100%;
    resize: none;
`;
const FocusCellStyled = styled.div<any>`
    background: #eee;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    border: 2px solid transparent;
    // transition: all 0.1s;
    height: 100%;
    font-size: 15px;
    white-space: pre-line;
    ${(props: any) =>
        props.isFocus &&
        `
        border-radius: 10px;
        box-shadow: 0 5px 25px rgba(0,0,0,.15);
        border: 2px solid #ddd;
        z-index: 1;
        background: #f9f9f9;
    `}
`;
const HightlineControl = styled.div`
    .enter .focus {
        border: 2px solid #000;
    }
`;

const TopicGroup = styled(Box)``;
const QuestionGroup = styled(Flex)`
    width: 100%;
`;

const TableHeading = styled(Flex)`
    text-align: center;
    font-size: 12px;
    font-weight: 500;
`;
function fillArray(num: number): number[] {
    return Array(num)
        .fill(0)
        .map((_, idx) => idx);
}
const Answer = styled.div`
    font-size: 14px;

    pre {
        background: #f1f1f1;
        padding: 10px;
        border-radius: 10px;
    }
`;
