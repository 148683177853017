import { Button, ButtonVariants, Modal } from "@fozg/fozg-ui-elements";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FiEdit, FiShare2 } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { Box, Flex, Text } from "rebass";
import moment from 'moment';
import StaticCard from "../../components/Card/StaticCard";
import Container from "../../components/Container";
import TextInput from "../../components/Forms/TextInput";
import Guide from "../../components/Guide";
import LevelDisplay from "../../components/LevelDisplay";
import Logo from "../../components/Logo";
import { OP } from "../../consts/vars";
import { OnePointService, OnePointServiceBase } from "../../services/OnePointService";
import MatrixMark, { setState, clearData, PointState, getState } from "../processing/MatrixMark";

export function calcTotalPoint(points: PointState[], topics: { topic: string; questions: Question[] }[]) {
    const pointMapper = {
        0: 0,
        1: 1,
        2: 2.5,
        3: 3.5,
        4: 5,
        5: 6,
    };

    var total = 0;
    topics.forEach((topic, t: number) => {
        topic.questions.forEach((q: Question, x: number) => {
            if (q.rate) {
                const p = (points[t].byQuestions[x].point ? points[t].byQuestions[x].point : 0) as keyof typeof pointMapper;
                total += (q.weight / 100) * pointMapper[p];
            }
        });
    });
    return total;
}

export default function () {
    const { sectionId } = useParams<{ sectionId: string }>();
    const [editMode, setEditmode] = useState(false);
    const [data, setData] = useState<InterviewResult | undefined>(undefined);
    const [modalShare, setModalShare] = useState(false);
    const [totalPoint, setTotalPoint] = useState(0);

    useEffect(() => {
        fetchData();
        return () => {
            clearData();
        };
    }, []);

    const fetchData = async () => {
        const resultsRes = await OnePointService.getListByName(OP.lists.results).getItems({
            where: [
                {
                    sections_id: sectionId,
                },
            ],
        });

        if (resultsRes.isError) {
            toast.error("Ú ù!");
            return;
        }

        const [firstResult] = resultsRes.data;
        if (firstResult) {
            setData(firstResult);
            setState({
                points: firstResult.points,
                topics: firstResult.topics,
                checklist: firstResult.checklist,
            });

            setTotalPoint(calcTotalPoint(firstResult.points, firstResult.topics));
        }
    };

    const onEditSave = () => {
        const { points } = getState();
        OnePointService.getListByName(OP.lists.results)
            .updateItem(data?._id as any, {
                points,
            })
            .then((res) => {
                if (res.isError) return toast.error(res.message);
                toast.success("Update thành công!");
                setEditmode(false);
            });
    };

    return (
        <>
            <Container>
                <Guide
                    width={["100%", 900]}
                    title={data?.session?.name}
                    subTitle={data?.session?.job_title}
                    lines={["Checklist: " + data?.checklist?.title, <Text fontSize={14}>Ngày phỏng vấn: {moment(data?.created).format("lll")}</Text>]}
                    actions={
                        editMode
                            ? [
                                  {
                                      title: "Lưu",
                                      onClick: onEditSave,
                                  },
                              ]
                            : [
                                  {
                                      title: "Sửa",
                                      iconBefore: <FiEdit />,
                                      variant: ButtonVariants.default,
                                      onClick: () => {
                                          setEditmode(true);
                                      },
                                  },
                                  {
                                      title: "Share kết quả phỏng vấn",
                                      iconBefore: <FiShare2 />,
                                      onClick: () => {
                                          setModalShare(true);
                                      },
                                  },
                              ]
                    }
                    rightPanel={
                        <Flex flexDirection="column">
                            <Logo />
                            {editMode ? (
                                <></>
                            ) : (
                                <div>
                                    <LevelDisplay point={totalPoint} />
                                </div>
                            )}
                        </Flex>
                    }
                />
            </Container>
            <StaticCard mt={4} marginX={[2, 4]} key={editMode.toString()}>
                <MatrixMark disable={!editMode} />
            </StaticCard>
            {modalShare && (
                <ModalShareResult
                    result={data}
                    sectionId={sectionId}
                    onClose={() => {
                        setModalShare(false);
                    }}
                />
            )}
        </>
    );
}

const ModalShareResult = ({ result, sectionId, onClose }: { result?: InterviewResult; sectionId: string; onClose: () => void }) => {
    const [link, setLink] = useState("");
    useEffect(() => {
        OnePointService.getListByName(OP.lists.sections)
            .getItemById(sectionId)
            .then((res) => {
                if (res.data.share_results_key) {
                    setLink(baseLink + res.data.share_results_key);
                }
            });
    }, []);

    const baseLink = document.location.origin + "/share/";
    const onCreatePublicLink = async () => {
        if (result && result._id) {
            const shareResult = await OnePointServiceBase.shareItem([result._id]);
            const updateResult = await OnePointService.getListByName(OP.lists.sections).updateItem(sectionId, {
                share_results_key: shareResult.data?._id,
            });

            if (!shareResult.isError && !updateResult.isError) {
                toast.success("Share thành công!");
                setLink(baseLink + shareResult.data?._id);
            } else {
                toast.error("Có gì đó không ổn rồi :/");
            }
        }
    };

    return (
        <Modal
            title={"Share kết quả phỏng vấn của " + result?.session.name}
            visible
            width={500}
            actions={[
                {
                    variant: ButtonVariants.outline,
                    title: "Đóng",
                    onClick: onClose,
                },
            ]}
        >
            <Box p={3}>
                <TextInput label="Public link" placeholder="Chưa có link được tạo" value={link}></TextInput>
                {!link && (
                    <Button mt={2} onClick={onCreatePublicLink}>
                        Tạo public link
                    </Button>
                )}
            </Box>
        </Modal>
    );
};
