import React from "react";
import { Flex, Text } from "rebass";
import styled from "styled-components";
import Container from "../Container";
import Logo from "../Logo";

export default () => (
    <FooterWrap>
        <Container>
            <Flex flexDirection="column" justifyContent="center" alignItems="center" p={50}>
                <Logo />
                <FooterText>Made by Fsofter, for Fsofter</FooterText>
                <Text fontSize={12} fontStyle="italic" color="#bbb">
                    Đây là ứng dụng thử nghiệm để số hoá quy trình tuyển dụng cho Fsoft, được tạo bởi PhongDH
                </Text>
            </Flex>
        </Container>
    </FooterWrap>
);

const FooterText = styled.div({
    paddingTop: 10,
    textAlign: "center",
    color: "#999",
    fontSize: 13,
    fontWeight: 600,
});
const FooterWrap = styled.div({
    marginTop: 100,
    background: "#fff",
    borderTop: "1px solid #eee",
});
