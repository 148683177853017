import React from "react";
import styled, { keyframes, css } from "styled-components";
import HeadingPage, { HeadingPageActionsType } from "../Heading/HeadingPage";

type Props = {
    pageTitle: string;
    children?: any;
    actions?: HeadingPageActionsType;
    backUrl?: string;
    color?: string;
};
export default function ({ pageTitle, children, actions, backUrl, color = "#000" }: Props) {
    return (
        <Wrapper>
            <HeadingPage title={pageTitle} actions={actions} backUrl={backUrl} color={color} />
            {children}
        </Wrapper>
    );
}

const fadeIn = keyframes`
  from {
    transform: translateX(200px);
    opacity: 0;
  }
  to {
    
  }
`;
const fadeinAnimate = css`
    ${fadeIn} .3s
`;

const Wrapper = styled.div`
    animation: ${fadeinAnimate};
    min-height: 80vh;
`;
