import React from "react";
import { Box } from "rebass";
import Container from "../../components/Container";
import CardRowSection from "../../components/Card/CardRowSection";
import HeadingSection from "../../components/Heading/HeadingSection";
import UpcommingSection from "../../modules/UpcommingSection";
import QuestionSection from "../../modules/QuestionSection";
import ChecklistHomeSection from "../../modules/checklists/ChecklistHomeSection";
import StaticCard from "../../components/Card/StaticCard";
import Guide from "../../components/Guide";
import { Link } from "react-router-dom";
import Logo from "../../components/Logo";
import IconInterview from "./IconInterview";

function Home() {
    return (
        <>
            <Container>
                <Guide
                    width={["100%"]}
                    title={<Logo />}
                    subTitle="Ứng dụng hỗ trợ phỏng vấn của Fsofter, by Fsofter"
                    lines={[
                        <>Để bắt đầu, bạn cần chuẩn bị dữ liệu phỏng vấn của riêng bạn, theo thứ tự sau:</>,
                        <>
                            <b>
                                <Link to="/question">Câu hỏi</Link>
                            </b>
                            : Bạn là người tự chuẩn bị câu hỏi thích hợp cho ứng viên mà bạn muốn, hãy tạo nhiều càng nhiều câu hỏi càng tốt, khi đó bạn có thể
                            dễ dàng tạo <b>Checklist</b> với bộ câu hỏi của bạn
                        </>,
                        <>
                            <b>Checklist</b>: Khá giống với file excel Checklist mà HR thường gửi cho bạn, hãy tạo nhiều checklist với các job_title khác nhau
                            như .Net Developer, FrontEnd Developer, Data Enginer...Bạn sẽ chủ động hơn khi chọn checklist phù hợp với vị trí ứng tuyển của ứng
                            viên
                        </>,
                        <>
                            <b>Buổi phỏng vấn</b>: Khi đã có Checklist, bạn có thể setup buổi phỏng vấn với vài thông tin cơ bản của ứng viên và chọn Checklist
                            thích hợp
                        </>,
                        <>
                            <b>Kết quả phỏng vấn</b>: Khi kết thúc Buổi phỏng vấn, kết quả sẽ được lưu lại ở đây, bạn có share kết quả cho HR hoặc quản lý.
                        </>,
                    ]}
                    rightPanel={<IconInterview />}

                />
                <StaticCard mb={4} mt={4}>
                    <UpcommingSection />
                </StaticCard>

                <StaticCard>
                    <HeadingSection title="Your checklist" viewMoreUrl="/checklist" />
                    <CardRowSection>
                        <ChecklistHomeSection />
                    </CardRowSection>
                </StaticCard>

                <Box mt={4}>
                    <StaticCard>
                        <QuestionSection isHomePage />
                    </StaticCard>
                </Box>
            </Container>
        </>
    );
}

export default Home;
