export enum FONT {
    CascadiaCode = "Cascadia Code",
    SegoeUI = "Segoe UI",
}

export const COLORS = {
    MAIN: "#14B1AB",
};

export const emotionTheme = {
    buttons: {
        default: {
            borderRadius: 10,
        },
        primary: {
            fontFamily: "Segoe UI",
            color: "#000e",
            bg: "#ddd",
            fontSize: 15,
            fontWeight: 500,
            lineHeight: 1,
            cursor: "pointer",
            padding: "4px 10px",
            height: 40,
            ":hover": {},
        },
        outline: {
            color: "#000",
            fontFamily: "Segoe UI",
            fontWeight: 500,
            cursor: "pointer",
            background: "none",
            outline: "none",
            padding: 0,
        },
        success: {
            bg: "#E0F2E7",
            borderRadius: 10,
            color: "#20AE54",
            padding: "4px 10px",
            fontFamily: "Segoe UI",
            outline: "red",
            fontWeight: 500,
            height: 40,
        },
        info: {
            bg: "#DDEBF6",
            color: "#1D85E1",
            padding: "4px 10px",
            fontFamily: "Segoe UI",
            borderRadius: 10,
            outline: "red",
            fontWeight: 500,
            height: 40,
        },
    },

    heading: {
        fontFamily: "Segoe UI",
    },
    input: {
        border: "1px solid #eee",
        background: "#eee",
        borderRadius: 8,
        outlineColor: COLORS.MAIN,
        fontSize: 14,
        padding: 10,
        fontFamily: "Segoe UI",
        ":focus": {
            background: "#fff",
        },
    },
    textarea: {
        border: "1px solid #eee",
        background: "#eee",
        borderRadius: 8,
        outlineColor: COLORS.MAIN,
        fontSize: 14,
        padding: 10,
        fontFamily: "Segoe UI",
        ":focus": {
            background: "#fff",
        },
    },
    text: {
        label: {
            fontWeight: 500,
            fontSize: 13,
        },
    },
};
