import React from "react";
import styled, { keyframes, css } from "styled-components";
import IconButton from "../Button/IconButton";

interface ModalAction {
    title: string;
    onClick?: (e: any) => void;
    variant?: string;
    icon?: any;
}
export type ModalProps = {
    onSelected?: void;
    selectedIds?: string[];
    title?: string | any;
    width?: number;
    actions?: ModalAction[];
    children?: any;
    visible?: boolean;
};
export default function ({
    selectedIds,
    title,
    width = 300,
    visible,
    actions,
    children,
}: ModalProps) {
    return (
        <div>
            {visible && <Backdrop />}
            {visible && (
                <BackdropTransparent>
                    <ModalWrap style={{ width }}>
                        <ModalHeader>{title}</ModalHeader>
                        <Inner>{children}</Inner>
                        <Footer>
                            {actions?.map((action, idx) => (
                                <IconButton
                                    icon={action.icon}
                                    onClick={action.onClick}
                                    variant={action.variant}
                                    key={idx}
                                >
                                    {action.title}
                                </IconButton>
                            ))}
                        </Footer>
                    </ModalWrap>
                </BackdropTransparent>
            )}
        </div>
    );
}

const fadeIn = keyframes`
  from {
    transform: translateY(100px);
    opacity: 0;
  }
`;
const fadeinAnimate = css`
    ${fadeIn} .3s
`;
const ModalWrap = styled.div`
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    min-height: 120px;
    animation: ${fadeinAnimate};
`;
const ModalHeader = styled.div({
    padding: "10px 20px 15px",
    borderBottom: "1px solid #eee",
});
const Inner = styled.div({
    // padding: 20,
});
const Footer = styled.div({
    borderTop: "1px solid #eee",
    display: "flex",
    justifyContent: "flex-end",
    padding: 10,
    ".button": {
        marginInlineEnd: 10,
    },
});
const Backdrop = styled.div({
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,.2)",
    zIndex: 1000,
});
const BackdropTransparent = styled.div({
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1001,
});
