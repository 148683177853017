import React from "react";
import { Flex, Heading } from "rebass";
import { Button, ButtonVariants } from "@fozg/fozg-ui-elements";
import { FONT } from "../../utils/theme";
import { Link } from "react-router-dom";

type Props = {
    title: string;
    viewMoreUrl?: string;
    viewMoreText?: string | boolean;
    small?: boolean;
};
export default function ({
    title,
    small,
    viewMoreUrl = "#",
    viewMoreText = "View More",
}: Props) {
    return (
        <Flex alignItems="center" justifyContent="space-between" mb={10}>
            <Heading fontSize={small ? 3 : 5}>
                {title}
            </Heading>
            <Link to={viewMoreUrl}>
                <Button
                    variant={ButtonVariants.outline}
                    onClick={() => {}}
                    iconAfter={
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            width={16}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M14 5l7 7m0 0l-7 7m7-7H3"
                            />
                        </svg>
                    }
                >
                    {viewMoreText}
                </Button>
            </Link>
        </Flex>
    );
}
