import React, { useEffect, useState } from "react";
import { TopNavigation } from "@fozg/fozg-ui-elements";
import { Flex, Box } from "rebass";
import { Link } from "react-router-dom";
import styled, { keyframes, css } from "styled-components";
import BgParttenSvg from "../svgs/BgParttenFirstSection.svg";
import Container from "../Container";
import useScrollToTop from "../../hooks/useScrollToTop";
import { reInitService } from "../../services/OnePointService";
import Logo from "../Logo";
import Footer from "./Footer";

export default function ({ children }: any) {
    useScrollToTop();
    const [ok, setOk] = useState(false);

    useEffect(() => {
        reInitService();
        setTimeout(() => {
            setOk(true);
        }, 5);
    }, []);

    return (
        <Wrapper loading={false}>
            <TopNavigation
                icon={
                    <Link to="/" color="#000">
                        <Logo />
                    </Link>
                }
            />
            {/* <Header /> */}
            <Box minHeight="calc(100vh - 150px)">{ok ? children : ""}</Box>
            <Footer />
        </Wrapper>
    );
}

const Wrapper = styled.div<{ loading?: boolean }>`
    background-size: 35px;
`;
