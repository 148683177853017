import React from "react";
import { Textarea, TextareaProps } from "@rebass/forms";
import { Text } from "rebass";

type Props = {
    label?: string;
    value?: string;
    onChange?: any;
};
export default function ({
    label,
    value,
    onChange = () => {},
    ...rest
}: Props & TextareaProps) {
    return (
        <div className="form-element">
            <Text fontWeight={500} fontSize={13}>
                {label}
            </Text>
            <Textarea
                value={value}
                onChange={onChange}
                mt={2}
                maxWidth="100%"
                padding={15}
                {...rest}
            ></Textarea>
        </div>
    );
}
