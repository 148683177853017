import React from "react";
import styled from "@emotion/styled";
import { Box, BoxProps } from "rebass";

const StaticCard = styled(Box)`
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0,0,0,.05);
`;

export default (props: BoxProps) => <StaticCard p={4} {...props} />;
