import React from "react";
import { StyleProvider, AuthorizedAppWrap } from "@fozg/fozg-ui-elements";
import styled from "styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import "./App.css";
import "react-datetime/css/react-datetime.css";

import Home from "./pages/Home";
import Checklist from "./pages/Checklist";
import ChecklistCreate from "./pages/Checklist/create";
import LoginedLayout from "./components/layouts/LoginedLayout";
import Question from "./pages/Question";
import QuestionCreate from "./pages/Question/create";
import TopMenu from "./components/TopMenu";
import { Box, Flex } from "rebass";
import InterviewSectionPage from "./pages/Section";
import ProcessingPage from "./pages/Processing";
import ResultPage from "./pages/Result";
import ResultDetail from "./pages/Result/detail";
import ShareLayout from "./components/layouts/ShareLayout";
import SharePage from "./pages/Share";

function App() {
    return (
        <div className="App">
            <Router>
                <StyleProvider theme="light">
                    <>
                        <Switch>
                            <LoginLayoutRoute path="/" component={Home} exact />
                            <LoginLayoutRoute path="/checklist" component={Checklist} exact />
                            <LoginLayoutRoute path="/section" component={InterviewSectionPage} exact />
                            <LoginLayoutRoute path="/checklist/create" component={ChecklistCreate} />
                            <LoginLayoutRoute path="/checklist/:id" component={ChecklistCreate} />
                            <LoginLayoutRoute path="/question" component={Question} exact />
                            <LoginLayoutRoute path="/question/create" component={QuestionCreate} exact />
                            <LoginLayoutRoute path="/question/:id" component={QuestionCreate} exact />
                            <LoginLayoutRoute path="/processing/:sectionId" component={ProcessingPage} exact />
                            <LoginLayoutRoute path="/result" component={ResultPage} exact />
                            <LoginLayoutRoute path="/result/:sectionId" component={ResultDetail} />
                            <ShareLayoutRoute path="/share/:key" component={SharePage} exact />
                        </Switch>
                        <Toaster />
                    </>
                </StyleProvider>
            </Router>
        </div>
    );
}

export default App;

const LoginLayoutRoute = ({ component: Component, ...rest }: any) => {
    return (
        <Route
            {...rest}
            render={(props) => (
                <AuthorizedAppWrap customLoginPage={LoginPage}>
                    <LoginedLayout>
                        <TopMenu />
                        <Component {...props} />
                    </LoginedLayout>
                </AuthorizedAppWrap>
            )}
        />
    );
};
const ShareLayoutRoute = ({ component: Component, ...rest }: any) => {
    return (
        <Route
            {...rest}
            render={(props) => (
                <ShareLayout>
                    <Component {...props} />
                </ShareLayout>
            )}
        />
    );
};
const LoginPage = ({ buttonLogin: LoginButton }: any) => (
    <Flex
        backgroundColor="#fffdf8"
        width="100vw"
        height="100vh"
        alignItems="center"
        justifyContent="flex-end"
        flexDirection="column"
        style={{
            backgroundImage: `url(/landing.svg)`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
        }}
    >
        {/* <Box maxWidth={['100%', 1000, '1400px']}>
            <img src="/landing.svg" alt="landing" style={{ maxWidth: "100%" }} />
        </Box> */}
        {/* <Box height={200} width={600} overflow="hidden">
            <img src="/logoColors.png" width="100%"></img>
        </Box> */}
        <LoginContainer>
            <LoginButton />
        </LoginContainer>
    </Flex>
);
const LoginContainer = styled.div`
    border-top: 1px solid #ccc;
    border-bottom: none;
    padding: 50px;
    background-color: #fff;
    width: 100%;
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
    // border-radius: 15px 15px 0 0;
`;
